
/*!
 *  Time selector form field.
 *
 *  @prop string className - Append a class name.
 *  @param boolean disabled - Whether the field should be disabled.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop boolean seconds - Whether to include seconds.
 *  @prop string value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";

import { TimeParse } from "Functions";

import TextField from "Components/UI/Field/TextField";

class TimeField extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            value: ""

        };

    }

    /**
     * Parse the initial value on mount.
     * 
     * @return void
     */

    componentDidMount() {

        const { value } = this.props;

        this.SetValue( value );

    }

    /**
     * Parse the updated value when one is received.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { value } = nextProps;
        const { formatted } = this.state;

        if ( value !== formatted ) {

            this.SetValue( value );

        }

    }

    /**
     * Update the field value when the time field changes.
     * 
     * @param object e - The event object.
     * @param string time - The new time.
     * 
     * @return void
     */

    SetTime = ( e, time ) => {

        const { id, onChange, seconds } = this.props;
        const Time = TimeParse( time, seconds );

        onChange( e, Time, id );

        this.setState( {
            
            value: Time
            
        } );

    }

    /**
     * Parse and set the value of the field-
     * 
     * @param string value - The unparsed value.
     * 
     * @return void
     */

    SetValue = ( value ) => {

        const { seconds } = this.props;
        const Time = TimeParse( value, seconds );

        this.setState( {

            value: Time

        } );

    }

    /**
     * Get the formatted value of the field.
     * 
     * @return string - The formatted value.
     */

    Value = () => {

        const { value } = this.state;

        return value;

    }

    render() {

        const { className, disabled, label, seconds } = this.props;
        const { value } = this.state;
        const CA = [ "TimeField" ];

        if ( className ) {

            CA.push( className );

        }

        const CS = CA.join( " " );

        return (
        
            <TextField
                
                className={ CS }
                disabled={ disabled }
                label={ label }
                maxLength={ seconds ? 8 : 5 }
                onBlur={ this.SetTime }
                placeholder={ seconds ? "00:00:00" : "00:00" }
                value={ value }
            
            />

        );

    }

}

TimeField.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    onChange: PropTypes.func,
    seconds: PropTypes.bool,
    value: PropTypes.string

};

TimeField.defaultProps = {

    className: "",
    disabled: false,
    id: "",
    label: "",
    onChange: () => {},
    seconds: false,
    value: ""

};

export default TimeField;