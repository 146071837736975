
/*!
 *  Date selector form field.
 *
 *  @prop string className - Append a class name.
 *  @param boolean disabled - Whether the field should be disabled.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop string value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./datefield.scss";

import { DateParse, RandomToken } from "Functions";

import Calendar from "Components/UI/Calendar";
import Icon from "Components/Layout/Icon";
import Sticky from "Components/Layout/Sticky";

class DateField extends React.Component {

    constructor( props ) {

        super( props );

        this.Token = RandomToken();

        this.state = {

            expand: false,
            value: ""

        };

    }

    /**
     * Set initial value.
     * 
     * @return void
     */

    componentDidMount() {

        const Value = this.ParseValue( this.props.value );

        this.setState( { value: Value } );

    }

    /**
     * Update value.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { value } = nextProps;

        if ( value !== this.props.value ) {

            const Value = this.ParseValue( value );
            
            this.setState( { value: Value } );

        }

    }

    /**
     *   Callback when a date is clicked in the calendar.
     * 
     *   @param object date - The date object. 
     *   
     *   @return void
     */

    OnPick = ( date ) => {

        const { disabled, id, onChange } = this.props;

        if ( disabled ) {

            return;

        }

        const Value = this.ParseValue( date );

        onChange( null, Value, id );

        this.setState( {

            expand: false,
            value: Value

        } );

    }

    /**
     *   Toggle between expanded/collapsed.
     *   
     *   @return void
     */

    OnToggle = () => {

        const { disabled } = this.props;
        const { expand } = this.state;

        if ( disabled ) {

            return;

        }

        this.setState( { expand: !expand } );

    }

    /**
     * Parse the field value.
     * 
     * @param string value - The raw value.
     * 
     * @return string - The parsed value.
     */

    ParseValue = ( value ) => {

        return DateParse( value, true );

    }

    /**
     * Reset to inital state.
     * 
     * @return void
     */

    Reset = () => {

        const Value = this.ParseValue( this.props.value );

        this.setState( { value: Value } );

    }

    /**
     * Get the field value.
     * 
     * @return string - The field value.
     */

    Value = () => {

        return this.ParseValue( this.state.value );

    }

    render() {

        const {
            
            className,
            disabled,
            disabledDates,
            disableWeekends,
            label,
            limitLower,
            limitUpper,
            redLetters
            
        } = this.props;

        const { expand, value } = this.state;
        const CA = [ "Field", "DateField" ];

        if ( className ) CA.push( className );
        if ( disabled ) CA.push( "Disabled" );
        if ( expand ) CA.push( "Expand" );

        const CS = CA.join( " " );

        let Pick;

        if ( expand ) {

            Pick = (

                <Sticky
                
                    align="right"
                    className="DateFieldPick"
                    onClose={ this.OnToggle }
                    width={ 300 }
                    
                >
                
                    <Calendar
                    
                        disabledDates={ disabledDates }
                        disableWeekends={ disableWeekends }
                        onClick={ this.OnPick }
                        limitLower={ limitLower }
                        limitUpper={ limitUpper }
                        redLetters={ redLetters }
                        selected={ value }
                    
                    />
                
                </Sticky>

            );

        }

        return (

            <div className={ CS }>

                { label ?  <label>

                    { label }

                </label> : "" }

                <div
                
                    className="Input"
                    onClick={ this.OnToggle }
                    ref="input"
                    title={ value }
                    
                >
                
                    <span>{ value }</span>

                    <Icon
                    
                        className="DateFieldChevron"
                        feather="ChevronDown"
                        
                    />
                
                </div>

                { Pick }

            </div>

        );

    }

}

DateField.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledDates: PropTypes.array,
    disableRedLetters: PropTypes.bool,
    disableWeekends: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    limitLower: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    limitUpper: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    onChange: PropTypes.func,
    redLetters: PropTypes.array,
    value: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] )

};

DateField.defaultProps = {

    className: "",
    disabled: false,
    disableDates: [],
    disableRedLetters: true,
    disableWeekends: false,
    id: "",
    label: "",
    limitLower: "",
    limitUpper: "",
    onChange: () => {},
    redLetters: [],
    value: ""

};

export default DateField;