
/**!
 *  Backend functionality
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import Auth from "Class/Auth";
import Globals from "Class/Globals";

class API {

    constructor() {

        this.ApiUrl = ( Globals.Settings.ApiUrl || "" ).replace( /\/?$/, "/" );
        this.Host = "";
        this.Listeners = {};

    }

    /**
     * Decode a server response.
     * 
     * @param string response - Unparsed response.
     * @param bool raw - Whether to return the parsed array without checking its length.
     * 
     * @return array|object|bool - Decoded JSON, array of decoded JSONs or 'false' on fail.
     */

    Decode = ( response, raw = false ) => {

        if ( typeof response !== "string" ) {

            return false;

        }

        const Rows = response.split( ( " " ).repeat( 3 ) );
        const Result = [];

        if ( !Rows ) {

            return false;

        }

        Rows.forEach( row => {

            if ( !row ) {

                return;

            }

            let Decoded;

            try {

                Decoded = JSON.parse( row );

            }

            catch (e) {

                const State = row.replace( / [\s\S]*|<[^>]*>|[^a-z]/gi, "" ).toLowerCase();

                Result.push( { error: 1, message: row, state: State } )

                return;

            }

            Result.push( Decoded );

        } );

        if ( raw ) {

            return Result.length ? Result : false;

        }

        switch ( Result.length ) {

            case 0:

                return false;

            case 1:

                return Result[0];

            default:

                return Result;

        }

    }

    /*
     * Parse a object into properly formatted form data.
     * 
     * @param object obj
     * 
     * @return object
     */

    FormData = ( obj ) => {

        /*
         * This is a recursive function for parsing a nested object.
         * 
         * @param object formData - A FormData instance.
         * @param object obj - Data to be appended into formData.
         * @param string parent - Optional. Parent data key.
         * 
         * @return object
         */

        const Append = ( formData, obj, parent ) => {

            let Item, Key;

            for ( let k in obj ) {

                Item = obj[ k ];
                Key = parent ? parent + "[" + k + "]" : k;

                if ( typeof Item === "object" ) {

                    Append( formData, Item, Key );

                }

                else {

                    formData.append( Key, Item ? Item : "" );

                }

            }

            return formData;

        }

        // Create a FormData instance and feed obj into it via append().
        const Data = new FormData();

        Append( Data, obj );

        return Data;

    }

    /**
     * Get all event listeners for an event.
     *
     * @param string event - The event name
     * 
     * @return array - An array of listeners.
     */

    Get = ( event ) => {

        if ( typeof this.Listeners[ event ] === "undefined" ) {

            this.Listeners[ event ] = [];

        }

        return this.Listeners[ event ];

    }

    /**
     * Add an event listener
     *
     * @param string event - The event name
     * @param function callback - The callback function
     * 
     * @return void
     */

    Listen = ( event, callback ) => {

        if ( typeof callback !== "function" ) {

            return;

        }

        const Listeners = this.Get( event );
        const Index = Listeners.indexOf( callback );

        if ( Index < 0 ) {

            Listeners.push( callback );

        }

    }

    /**
     * Remove an event listener
     *
     * @param string event - The event name
     * @param function callback - The callback function
     * 
     * @return void
     */

    Remove = ( event, callback ) => {

        const Listeners = this.Get( event );
        const Index = Listeners.indexOf( callback );

        if ( Index < 0 ) {

            return;

        }

        Listeners.splice( Index, 1 );

    }

    /*
     * Make a XHR request to the backend server API.
     * 
     * @param string endpoint
     * @param object data - Optional form data.
     * @param function callback - Called when the request is completed.
     * @param function progress - Called when XHR progress updates.
     * @param function statechange - Called when XHR state changes.
     * 
     * @return object - The XHR object.
     */

    Request = ( endpoint, data, callback, progress, statechange, raw ) => {

        // Since data is optional, the second parameter may instead
        // be the callback method.
        if ( !callback ) {

            callback = data;

        }

        if ( typeof callback !== "function" ) {

            callback = () => {};

        }

        const Url = this.Url( endpoint );

        if ( !Url ) {

            console.error( "No API URL has been configured." );

            callback( false );

            return;

        }

        const Data = Object.assign( Auth.Ready() ? {

            host: this.Host,
            user: Auth.UserId,
            token: Auth.Token

        } : {}, typeof data === "object" ? data : {} );

        const FormData = this.FormData( Data );
        const Xhr = new XMLHttpRequest();

        Xhr.open( "POST", Url, true );

        // On complete.
        Xhr.addEventListener( "load", (e) => {

            // Check for 200.
            if ( Xhr.readyState !== XMLHttpRequest.DONE ) {

                return;

            }

            // Check that the response is a proper JSON.
            const Response = raw ? Xhr.response : this.Decode( Xhr.response );

            if ( Response ) {

                callback( Response );

            }

            else {

                callback( raw ? false : {

                    data: false,
                    error: "Server error.",
                    message: ""

                } );

            }

        }, false );

        // On fail.
        Xhr.addEventListener( "error", (e) => {

            callback( raw ? false : {

                data: false,
                error: "Server error.",
                message: ""

            } );

        }, false );

        if ( typeof statechange === "function" ) {

            let Last = 0, Read;

            Xhr.addEventListener( "readystatechange", (e) => {

                Read = Xhr.response.substr( Last );
                Last = Xhr.response.length;

                const Response = this.Decode( Read, true );

                if ( !Response ) {

                    return;

                }

                Response.forEach( r => statechange( r, Xhr ) );

            } );

        }

        // On progress update. If a callback has been supplied.
        if ( typeof progress === "function" ) {

            Xhr.upload.addEventListener( "progress", (e) => {

                progress( e.loaded, e.total );

            }, false );

        }

        Xhr.send( FormData );

        return Xhr;

    }

    SetHost = ( host ) => {

        this.Host = host;

    }

    /*
     * Make a XHR request to the backend server API and run the
     * callback for every state change. Shorthand for Request.
     * 
     * @param string endpoint
     * @param object data - Optional form data.
     * @param function callback - Called when XHR state changes.
     * @param function progress - Called when XHR progress updates.
     * 
     * @return void
     */

    Stream = ( endpoint, data, callback, progress ) => {

        return this.Request( endpoint, data, null, progress, callback );

    }

    /**
     * Trigger an event
     *
     * @param string event - The event name
     * @param mixed data - Optional. Data to send to the event listeners.
     * 
     * @return void
     */

    Trigger = ( event, data ) => {

        const Listeners = this.Get( event );

        Listeners.forEach( callback => {

            callback( data );

        } );

    }

    /**
     * Create an API URL.
     * 
     * @param string uri - The request URI.
     * 
     * @return string - The URL
     */

    Url = ( uri ) => {

        return this.ApiUrl ? this.ApiUrl + uri : '';

    }

}

export default new API();