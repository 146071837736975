
/*!
 *  Content type select form field.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import SelectField from "Components/UI/Field/SelectField";

class ContentTypeField extends React.Component {

    constructor( props ) {

        super( props );

        this.Types = {};

        this.Types[ "content::article" ] = "Article";
        //this.Types[ "capturedvideo" ] = "Captured Video";
        this.Types[ "community" ] = "Community";
        this.Types[ "externalLink" ] = "External Link";
        this.Types[ "event" ] = "Event";
        //this.Types[ "learningplan" ] = "Learning Plan";
        this.Types[ "question" ] = "Question";
        this.Types[ "scormcourse" ] = "Scorm Course";
        //this.Types[ "topic" ] = "Topic";
        this.Types[ "uploadedfile" ] = "Uploaded File";
        this.Types[ "user" ] = "User";
        this.Types[ "video" ] = "Video";

    }

    render() {

        const { className } = this.props;
        const CA = [ "ContentTypeField" ];

        if ( className ) {

            CA.push( className );

        }

        const CS = CA.join( " " );
 
        return <SelectField
        
                    { ...this.props }

                    className={ CS }
                    options={ this.Types }
                    
                />

    }

}

ContentTypeField.propTypes = {

    className: PropTypes.string

};

ContentTypeField.defaultProps = {

    className: ""

};

export default ContentTypeField;