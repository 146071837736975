
/**!
 *  Widget wrapper with title, filters, call to action etc.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./widgetwrapper.scss";

import LoadImage from "Components/Layout/LoadImage";
import ScrollView from "Components/UI/ScrollView";

class WidgetWrapper extends React.Component {

    /**
     * Output the widget wrapper background image.
     * 
     * @param string src - Alternative background src.
     * 
     * @return JSX - The background image.
     */

    BackgroundImage = ( src ) => {

        const { backgroundImage } = this.props;
        const BackgroundImage = src || ( backgroundImage && backgroundImage.length ) ? parseInt( backgroundImage[0], 10 ) : false;

        return BackgroundImage ? <LoadImage
                
            className="WidgetWrapperBackground"
            size="large"
            srcId={ BackgroundImage }
        
        /> : "";

    }

    render() {

        const {
            
            background,
            backgroundImage,
            backgroundOverlay,
            children,
            className,
            grayscale,
            title,
            toolbar
            
        } = this.props;

        const BackgroundImage = ( backgroundImage && backgroundImage.length ) ? parseInt( backgroundImage[0], 10 ) : false;
        const CA = [ "WidgetWrapper" ];

        if ( BackgroundImage ) {

            CA.push( "HasBackground" );

        }

        else {

            switch ( background ) {

                case "gradient":

                    CA.push( "Gradient" );
                    break;

                case "orange":

                    CA.push( "Orange" );
                    break;

                case "pink":

                    CA.push( "Pink" );
                    break;

                case "purple":

                    CA.push( "Purple" );
                    break;

                case "white":

                    CA.push( "White" );
                    break;

                default:

            }

        }

        if ( backgroundOverlay ) CA.push( "HasBackgroundOverlay" );
        if ( grayscale ) CA.push( "Grayscale" );
        if ( toolbar ) CA.push( "HasToolbar" );
        if ( className ) CA.push( className );

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                { this.BackgroundImage() }

                <div className="WidgetWrapperTitle">

                    <h3>{ title }</h3>

                    { toolbar ? <div className="WidgetWrapperToolbar">{ toolbar }</div> : "" }

                </div>

                <ScrollView className="WidgetWrapperContent" >

                    { children }

                </ScrollView>

            </div>

        );

    }

}

WidgetWrapper.propTypes = {

    background: PropTypes.string,
    backgroundImage: PropTypes.oneOfType( [ PropTypes.array, PropTypes.string ] ),
    className: PropTypes.string,
    grayscale: PropTypes.bool,
    title: PropTypes.string,
    toolbar: PropTypes.oneOfType( [ PropTypes.object, PropTypes.string ] )

};

WidgetWrapper.defaultProps = {

    background: "",
    backgroundImage: "",
    className: "",
    grayscale: false,
    title: "",
    toolbar: ""

};

export default WidgetWrapper;