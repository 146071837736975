
/*!
 *  Expandable field with subfields.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop array fields - Subfields.
 *  @prop boolean flip - Whether to make the options appear above the field instead of below.
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback function.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop string placeholder - Placeholder/unselected value.
 *  @prop function selectedLabel - Optional callback to set the selected label.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./filterfield.scss";

import Form from "Components/UI/Form";
import Icon from "Components/Layout/Icon";
import Sticky from "Components/Layout/Sticky";

import { ArrayClone } from "Functions";

class FilterField extends React.Component {

    constructor( props ) {

        super( props );

        this.Options = {};

        this.state = {

            expand: false,
            width: 200,

        };

    }

    /**
     * Add listeners on mount.
     * 
     * @return void
     */

    componentDidMount() {

        window.addEventListener( "resize", this.SetSize );

    }

    /**
     * Remove listeners on unmount.
     * 
     * @return void
     */

    componentWillUnmount() {

        window.removeEventListener( "resize", this.SetSize );

    }
    
    /**
     * Callback when the subfields are edited.
     * 
     * @param object e - The event object.
     * @param string key - The field key.
     * @param mixed value - The new field value.
     * 
     * @return void
     */

    OnChange = ( e, key, value ) => {

        const { closeOnChange, id, onChange, values } = this.props;
        const Values = ArrayClone( values );
        const Current = Values[ key ];

        if ( Current === value ) {

            return;

        }

        Values[ key ] = value;

        onChange( e, Values, id );

        if ( closeOnChange ) {

            this.setState( {
                
                expand: false
            
            } );

        }

    }

    /**
     * Toggle between expanded/collapsed.
     * 
     * @return void
     */

    OnToggle = () => {

        const { disabled } = this.props;
        const { expand } = this.state;
        const { input } = this.refs;

        if ( disabled || !input ) {

            return;

        }

        if ( !expand ) {

            this.SetSize();

        }

        this.setState( { expand: !expand } );

    }

    /**
     * Get the selected options label.
     * 
     * @return string - The selected options label.
     */

    Selected = () => {

        const { placeholder, selectedLabel } = this.props;
        const { value } = this.state;

        const SelectedLabel = selectedLabel( value );

        if ( SelectedLabel ) {

            return SelectedLabel;

        }

        return placeholder || "Filter";

    }

    /**
     * Adjust the width of the dropdown menu when the client resizes.
     * 
     * @return void
     */

    SetSize = () => {

        const { input } = this.refs;

        if ( !input ) {

            return;

        }

        this.setState( { width: input.offsetWidth } );

    }

    /**
     * Get the field label as its' value.
     * 
     * @return string - The field label.
     */

    Value = () => {
        
        return this.Selected();

    }

    render() {

        const { className, disabled, error, fields, flip, label, values } = this.props;
        const { expand, width } = this.state;
        const CA = [ "Field", "FilterField" ];

        if ( className ) CA.push( className );
        if ( disabled || !fields.length ) CA.push( "Disabled" );
        if ( error ) CA.push( "Error" );
        if ( expand ) CA.push( "Expand" );
        if ( flip ) CA.push( "Flip" );

        const CS = CA.join( " " );
        const Selected = this.Selected();
        
        let Menu;

        if ( expand ) {

            Menu = (

                <Sticky
                
                    align="right"
                    className="FilterFieldForm"
                    flip={ flip }
                    onClose={ this.OnToggle }
                    width={ width }
                    
                >
                
                    <Form

                        content={ values }
                        fields={ fields }
                        onEdit={ this.OnChange }
                    
                    />
                
                </Sticky>

            );

        }

        return (

            <div className={ CS }>

                {
                
                    label ? (
                    
                        <label onClick={ this.OnToggle }>
                    
                            { label }
                        
                        </label>
                    
                    ) : ""

                }

                <div

                    className="FilterFieldInput"
                    onClick={ this.OnToggle }
                    ref="input"
                    title={ Selected }

                >

                    <span className="FilterFieldLabel">{ Selected }</span>

                    <Icon
                    
                        className="FilterFieldIcon"
                        feather={ flip ? "ChevronUp" : "ChevronDown" }
                        
                    />

                </div>

                { Menu }

            </div>            

        );

    }

}

FilterField.propTypes = {

    className: PropTypes.string,
    closeOnChange: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    fields: PropTypes.object,
    flip: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    selectedLabel: PropTypes.func,
    values: PropTypes.object

};

FilterField.defaultProps = {

    className: "",
    closeOnChange: true,
    disabled: false,
    error: false,
    fields: {},
    flip: false,
    id: "",
    label: "",
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    placeholder: "",
    selectedLabel: () => {},
    values: {}

};

export default FilterField;