/**!
 *  Collection of community links.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./communities.scss";
import Auth from "Class/Auth";
import Fuse from "Class/Fuse";
import {CanForEach, RandomToken} from "Functions";
import ContentItem from "Components/UI/ContentItem";
import Error from "Components/Feedback/Error";
import Slider from "Components/UI/Slider";
import Spinner from "Components/Feedback/Spinner";

class WidgetCommunities extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {    
                default: "Communities",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"   
            },
            usersOwn:
            {
                label: "Show users communities",
                type: "checkbox"
            },
            communities:
            {
                addLabel: "Add community",
                displayIf: ["usersOwn", "!=", true],
                onLabel: ["content", "content", 0],
                label: "Communities",
                type: "repeater",
                nameKey: "label",
                fields:
                {
                    content:
                    {
                        label: "Community",
                        type: "content",
                        types: ["community"]
                    },
                    contentNotice:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Label will replace the community name if specified.",
                        type: "notice"
                    },
                    label:
                    {    
                        label: "Label"   
                    },
                    image:
                    {
                        label: "Image",
                        type: "image"
                    },
                    grayscale:
                    {
                        label: "Grayscale",
                        type: "checkbox",
                        default: true
                    }
                }
            }
        });
        this.Mounted = false;
        this.Name = "Community links";
        this.state = {
            error: false,
            loading: false,
            own: false
        };
    }

    componentDidMount()
    {
        const {usersOwn} = this.Content();
        this.Mounted = true;
        if (usersOwn)
        {
            this.LoadOwn();
        }
    }

    componentDidUpdate(prevProps)
    {
        const {usersOwn: u1} = this.props.content;
        const {usersOwn: u2} = prevProps.content;
        if (u1 && !u2)
        {
            this.LoadOwn();
        }
    }

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    LoadOwn = () =>
    {
        this.setState({
            error: false,
            loading: true,
            own: false
        });
        Fuse.Request(`users/${Auth.UserId}/communities`, {all: true}, response =>
        {
            const {communities} = response || {};
            const Own = [];
            if (CanForEach(communities))
            {
                communities.forEach(community =>
                {
                    const {
                        accessible,
                        id,
                        image_url,
                        name,
                        type
                    } = community;
                    Own.push({
                        accessible,
                        description: "",
                        id,
                        name,
                        preview: image_url,
                        raw: community,
                        type
                    });
                });
                this.setState({loading: false, own: Own});
            }
            else
            {
                this.setState({error: true, loading: false});
            }
        });
    }

    render()
    {
        const {communities, title, usersOwn} = this.Content();
        const {rowHeight} = this.props;
        const {error, loading, own} = this.state;
        const CS = this.ClassNames(["WidgetCommunities"], true);
        const Items = [];
        if (usersOwn)
        {
            if (loading)
            {
                Items.push(<Spinner        
                    className="WidgetCommunitiesSpinner"
                    key="spinner"
                    overlay={true}
                />);
            }
            else if (error)
            {
                Items.push(<Error
                    button="Try again"
                    className="WidgetCommunitiesError"
                    key="error"
                    label="Unable to load communities"
                    onClick={this.OnReload}
                />);
            }
            else if (!own || !own.length)
            {
                Items.push(   
                    <div className="WidgetCommunitiesEmpty" key="empty">
                        You're not a member of any communities
                    </div>
                );
            }
            else
            {
                own.forEach(community =>
                {
                    Items.push(
                        <div className="WidgetCommunitiesItem" key={community.id}>
                            <ContentItem
                                contentObject={community}
                                width={200}
                                height={250}
                                sizeMultiplier={Math.min(1, rowHeight)}
                            />
                        </div>
                    );
                });
            }
        }
        else if (CanForEach(communities))
        {
            communities.forEach(link =>
            {
                // Assign a unique id/key to each link to avoid re-render.            
                if (!link.id)
                {
                    link.id = RandomToken();
                }
                Items.push(
                    <div className="WidgetCommunitiesItem" key={link.id}>
                        <ContentItem
                            content={link}
                            width={200}
                            height={250}
                            sizeMultiplier={Math.min(1, rowHeight)}
                        />
                    </div>
                );
            });
        }
        return (
            <div className={CS} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                <div className="WidgetCommunitiesContent">
                    <h2>{title}</h2>
                    <Slider className="WidgetCommunitiesItems" style={{height: 280 * rowHeight}}>
                        {Items}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default WidgetCommunities;