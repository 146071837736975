
/**!
 *  Interactive items slider.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./slider.scss";

import { TweenLite, Power3, CSSPlugin } from "gsap/all";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import IconButton from "Components/UI/IconButton";

const Plugins = [ ScrollToPlugin, CSSPlugin ]

class Slider extends React.Component {

    constructor( props ) {
    
        super( props );

        this.Plugins = Plugins;
        this.UseGsap = false;

        this.state = {

            scrollLeft: false,
            scrollRight: false

        };

    }

    /**
     * Show/hide scroll buttons on mount.
     * 
     * @return void
     */

    componentDidMount() {

        this.OnScroll();
        window.addEventListener( "resize", this.OnScroll );

    }

    /**
     * Show/hide scroll buttons on update.
     * 
     * @return void
     */

    componentDidUpdate() {

        this.OnScroll();

    }

    /**
     * Remove listeners on unmount.
     * 
     * @return void
     */

    componentWillUnmount() {

        window.removeEventListener( "resize", this.OnScroll );

    }

    /**
     * Show/hide scroll buttons when the user scrolls the container.
     * 
     * @return void
     */

    OnScroll = () => {

        const { wrapper } = this.refs;
        const { scrollLeft, scrollRight } = this.state;

        if ( !wrapper ) {

            return;

        }

        const Limit = wrapper.scrollWidth - wrapper.offsetWidth;
        const Scrolled = wrapper.scrollLeft;
        const ScrollLeft = Scrolled > 0;
        const ScrollRight = Scrolled < Limit;

        if ( scrollLeft !== ScrollLeft || scrollRight !== ScrollRight ) {

            this.setState( {

                scrollLeft: ScrollLeft,
                scrollRight: ScrollRight
                
            } );

        }

    }

    /**
     * Callback when the left scroll button is clicked.
     * 
     * @return void
     */

    ScrollLeft = () => {

        const { wrapper } = this.refs;

        if ( !wrapper ) {

            return;

        }

        const Items = wrapper.childNodes;
        const Scrolled = wrapper.scrollLeft;
        const Width = wrapper.offsetWidth;

        // Find the left most item that lies within one container length of
        // the left edge and then scroll to it.
        let X = -1;

        Items.forEach( item => {

            const Offset = item.offsetLeft - 10;

            if ( X >= 0 || Offset - Scrolled < -Width ) {

                return;

            }

            X = Offset;

        } );

        X = Math.max( X, 0 );

        if ( X < Width / 2 ) {

            X = 0;

        }

        if ( this.UseGsap ) {

            TweenLite.to( wrapper, 1, {

                scrollTo: { x: X, y: 0 },
                ease: Power3.easeInOut

            } );
        
        }

        else {

            wrapper.scrollLeft = X;

        }

    }

    /**
     * Callback when the right scroll button is clicked.
     * 
     * @return void
     */

    ScrollRight = () => {

        const { wrapper } = this.refs;

        if ( !wrapper ) {

            return;

        }

        const Items = wrapper.childNodes;
        const Scrolled = wrapper.scrollLeft;
        const Width = wrapper.offsetWidth;
        const Limit = wrapper.scrollWidth - Width;

        // Find the right most item that lies within the visible part of the
        // container and scroll to it.
        let X;

        Items.forEach( item => {

            const Offset = item.offsetLeft;
            
            if ( Offset - Scrolled > Width ) {

                return;

            }

            X = Offset - 20;

        } );

        X = Math.min( Math.max( X, 350 ), Limit );

        if ( Limit - X < 150 ) {

            X = Limit;

        }

        if ( this.UseGsap ) {

            TweenLite.to( wrapper, 1, {

                scrollTo: { x: X, y: 0 },
                ease: Power3.easeInOut

            } );

        }

        else {

            wrapper.scrollLeft = X;

        }

    }

    render() {

        const { children, className } = this.props;
        const { scrollLeft, scrollRight } = this.state;
        const CA = [ "Slider" ];

        if ( className ) {

            CA.push( className );

        }

        if ( scrollLeft ) {

            CA.push( "ScrollLeft" );

        }

        if ( scrollRight ) {

            CA.push( "ScrollRight" );

        }

        if ( !this.UseGsap ) {

            CA.push( "NoGsap" );

        }

        const CS = CA.join( " " );

        return (

            <div { ...this.props } className={ CS }>

                <div className="SliderContent">

                    <div
                    
                        className="SliderWrapper"
                        onScroll={ this.OnScroll }
                        ref="wrapper"
                        
                    >
                    
                        { children }

                    </div>

                    <div className="SliderShadow Left"></div>
                    <div className="SliderShadow Right"></div>

                    <div className="SliderButtonContainer Left">

                        <IconButton
                        
                            className="SliderButton ItemBackground"
                            feather="ArrowLeft"
                            onClick={ this.ScrollLeft }
                            ref="left"
                            size={ 36 }
                        
                        />

                    </div>

                    <div className="SliderButtonContainer Right">

                        <IconButton
                    
                            className="SliderButton ItemBackground"
                            feather="ArrowRight"
                            onClick={ this.ScrollRight }
                            ref="right"
                            size={ 36 }
                        
                        />

                    </div>

                </div>
                
            </div>

        );

    }

}

Slider.propTypes = {

    className: PropTypes.string

};

Slider.defaultProps = {

    className: ""

};

export default Slider;