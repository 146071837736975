
/*!
 *  Calendar view.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the calendar should be disabled.
 *  @prop array disableDates - Array of dates that should be disabled.
 *  @prop boolean disableWeekends - Whether weekend dates should be disabled.
 *  @prop string|date limitLower - Disable all dates before (and including) this date.
 *  @prop string|date limitUpper - Disable all dates after (and including) this date.
 *  @prop function onChange - Callback when a date is clicked.
 *  @prop array redLetters - Array of dates that are red letter days.
 *  @prop string|date selected - Selected date.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./calendar.scss";

import { DateParse, DateStamp, ObjectCompare, ObjectExtend } from "Functions";

import IconButton from "Components/UI/IconButton";

class Calendar extends React.Component {

    constructor( props ) {

        super( props );

        this.Days = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
        this.Months = [
            
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
            
        ];
        this.Today = DateStamp();

        const Now = new Date();

        this.state = {

            disabled: [],
            limitLower: false,
            limitUpper: false,
            redLetters: [],
            selectedDate: Now.getDate(),
            selectedMonth: Now.getMonth(),
            selectedYear: Now.getFullYear()

        };

    }

    /**
     * Setup calendar on mount.
     * 
     * @return void
     */

    componentDidMount() {

        const { disabledDates, limitLower, limitUpper, redLetters, selected } = this.props;
        const State = this.ParseSelected( selected );

        State.disabled = DateParse( disabledDates, true );
        State.redLetters = DateParse( redLetters, true );

        State.limitLower = limitLower ? DateParse( limitLower ) : false;
        State.limitUpper = limitUpper ? DateParse( limitUpper ) : false;

        this.setState( State );

    }

    /**
     * Reset the calendar when new props are received.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { disabledDates, limitLower, limitUpper, redLetters, selected } = nextProps;
        const State = {};

        if ( selected !== this.props.selected ) {

            const Selected = this.ParseSelected( selected );

            ObjectExtend( State, Selected );

        }

        if ( !ObjectCompare( disabledDates, this.props.disableDates ) ) {

            State.disabled = DateParse( disabledDates, true );

        }

        if ( !ObjectCompare( redLetters, this.props.redLetters ) ) {

            State.redLetters = DateParse( redLetters, true );

        }

        if ( limitLower !== this.props.limitLower ) {

            State.limitLower = limitLower ? DateParse( limitLower ) : false;
        
        }

        if ( limitUpper !== this.props.limitUpper ) {

            State.limitUpper = limitUpper ? DateParse( limitUpper ) : false;
        
        }

        this.setState( State );

    }

    /**
     * Output a date in the calendar.
     * 
     * @param integer date - The date (1-31).
     * @param integer month - The month (0-11).
     * @param integer year - The year.
     * @param integer day - The weekday (0-6).
     * 
     * @return JSX - The date item.
     */

    Item = ( date, month, year, day ) => {

        const {
            
            selectedDate,
            selectedMonth,
            selectedYear,
            redLetters
            
        } = this.state;

        const CA = [ "CalendarDate" ];
        const Formatted = DateStamp( [ date, month, year ], true );
        const Disabled = this.ItemDisabled( day, Formatted );

        if ( Disabled ) CA.push( "Disabled" );
        if ( selectedMonth !== month ) CA.push( "Outside" );
        if ( redLetters.indexOf( Formatted ) >= 0 ) CA.push( "RedLetter" );
        if ( selectedDate === date && selectedMonth === month && selectedYear === year ) CA.push( "Selected" );
        if ( Formatted === this.Today ) CA.push( "Today" );

        const CS = CA.join( " " );

        return (

            <div
            
                className={ CS }
                key={ `${year}-${month}-${date}` }
                onClick={ Disabled ? null : e => this.OnDate( e, date, month, year ) }
                title={ Formatted }
                
            >
            
                <span>{ date }</span>
            
            </div>

        );

    }

    /**
     * Check if a date should be disabled.
     * 
     * @param integer day - The weekday (0-6).
     * @param string formatted - The formatted date (YYYY-MM-DD).
     * 
     * @return boolean - Whether the date should be disabled.
     */

    ItemDisabled = ( day, formatted ) => {

        const { disabled, limitLower, limitUpper, redLetters } = this.state;
        const { disableRedLetters, disableWeekends } = this.props;

        // Disable weekend?
        if ( disableWeekends && ( !day || day > 5 ) ) {

            return true;

        }

        // Has this date been specifically disabled?
        if ( disabled.indexOf( formatted ) >= 0 ) {

            return true;

        }

        // Disable red letter days?
        if ( disableRedLetters && redLetters.indexOf( formatted ) >= 0 ) {

            return true;

        }

        // Check lower limit.
        if ( limitLower && formatted < DateStamp( limitLower, true ) ) {

            return true;

        }

        // Check upper limit.
        if ( limitUpper && formatted > DateStamp( limitUpper, true ) ) {

            return true;

        }

        return false;

    }

    /**
     * Change the selected date.
     * 
     * @param integer date - The date (1-31).
     * @param integer month - The month (0-11).
     * @param integer year - The year.
     * 
     * @return object - The seleted date as JS date object.
     */

    OnChange = ( date, month, year ) => {

        const { onChange } = this.props;
        const { selectedDate, selectedMonth, selectedYear } = this.state;

        const D = new Date( year || selectedYear, month || selectedMonth, date || selectedDate, 0, 0, 0, 0 );

        onChange( D );

        return D;

    }

    /**
     * Callback when a date is clicked in the calendar.
     * 
     * @param object e - The click event.
     * @param integer selectedDate - The selected date (1-31).
     * @param integer selectedMonth - The selected month (0-11).
     * @param integer selectedYear - The selected year.
     * 
     * @return void
     */

    OnDate = ( e, selectedDate, selectedMonth, selectedYear ) => {

        const { disabled, onClick } = this.props;

        if ( disabled ) {

            return;

        }

        this.setState( {

            selectedDate,
            selectedMonth,
            selectedYear

        } );

        const D = this.OnChange( selectedDate, selectedMonth, selectedYear );

        onClick( D );

    }

    /**
     * Callback when the forward button is clicked.
     * 
     * @return void
     */

    OnNext = () => {

        const { disabled } = this.props;

        if ( disabled ) {

            return;

        }

        const { selectedMonth, selectedYear } = this.state;
        let Month = selectedMonth + 1;
        let Year = selectedYear;

        if ( Month > 11 ) {

            Month = 0;
            Year += 1;

        }

        let Day = 0;
        let D, F;

        do {

            Day++;

            D = new Date( Year, Month, Day );
            F = DateStamp( [ Day, Month, Year ], true );

        } while ( Day < this.Days[ Month ] && this.ItemDisabled( D.getDay(), F ) );

        this.setState( {
            
            selectedDate: Day,
            selectedMonth: Month,
            selectedYear: Year
            
        } );

        this.OnChange();

    }

    /**
     * Callback when the back button is clicked.
     * 
     * @return void
     */

    OnPrevious = () => {

        const { disabled } = this.props;

        if ( disabled ) {

            return;

        }

        const { selectedMonth, selectedYear } = this.state;
        let Month = selectedMonth - 1;
        let Year = selectedYear;

        if ( Month < 0 ) {

            Month = 11;
            Year -= 1;

        }

        let Day = 0;
        let D, F;

        do {

            Day++;

            D = new Date( Year, Month, Day );
            F = DateStamp( [ Day, Month, Year ], true );

        } while ( Day < this.Days[ Month ] && this.ItemDisabled( D.getDay(), F ) );

        this.setState( {
            
            selectedDate: Day,
            selectedMonth: Month,
            selectedYear: Year
            
        } );

        this.OnChange();
        
    }

    /**
     * Format a raw date.
     * 
     * @param mixed date - The unparsed date.
     * 
     * @return object - The formatted date.
     */

    ParseSelected = ( date ) => {

        const [ selectedDate, selectedMonth, selectedYear ] = DateParse( date );

        return {

            selectedDate,
            selectedMonth,
            selectedYear

        };

    }

    render() {

        const { className, disabled } = this.props;
        const { limitLower, limitUpper, selectedMonth, selectedYear } = this.state;
        const CA = [ "Calendar" ];

        if ( className ) CA.push( className );
        if ( disabled ) CA.push( "Disabled" );

        const CS = CA.join( " " );
        const Days = Array.from( this.Days );
        const First = new Date( selectedYear, selectedMonth, 1, 0, 0, 0, 0 );
        const FirstDay = First.getDay() - 1;
        const PreviousMonth = selectedMonth ? selectedMonth - 1 : 11;
        const PreviousYear = PreviousMonth > selectedMonth ? selectedYear - 1 : selectedYear;
        const Month = this.Months[ selectedMonth ];
        const NextMonth = ( selectedMonth + 1 ) % 12;
        const NextYear = NextMonth < selectedMonth ? selectedYear + 1 : selectedYear;

        if ( selectedYear % 4 === 0 ) {

            Days[1] = 29;

        }

        const Dates = [];
        let Total = 0;

        for ( let i = 0; i < FirstDay; i++ ) {

            let D = Days[ PreviousMonth ] - FirstDay + i + 1;

            Dates.push( this.Item( D, PreviousMonth, PreviousYear, ( Total + 1 ) % 7 ) );

            Total++;

        }

        for ( let i = 0; i < Days[ selectedMonth ]; i++ ) {

            let D = i + 1;

            Dates.push( this.Item( D, selectedMonth, selectedYear, ( Total + 1 ) % 7 ) );

            Total++;

        }

        for ( let i = Total; i < 42; i++ ) {

            let D = i - Total + 1;

            Dates.push( this.Item( D, NextMonth, NextYear, ( i + 1 ) % 7 ) );

        }

        return (

            <div className={ CS }>

                <div className="CalendarHeading">
            
                    <IconButton
                    
                        className="CalendarPrevious"
                        disabled={ disabled || ( limitLower && selectedMonth <= limitLower[1] && selectedYear <= limitLower[2] ) || ( limitLower && selectedYear < limitLower[2] ) }
                        feather="ChevronLeft"
                        onClick={ this.OnPrevious }
                    
                    />

                    <IconButton
                    
                        className="CalendarNext"
                        feather="ChevronRight"
                        disabled={ disabled || ( limitUpper && selectedMonth >= limitUpper[1] && selectedYear >= limitUpper[2] ) || ( limitUpper && selectedYear > limitUpper[2] ) }
                        onClick={ this.OnNext }
                    
                    />

                    <div className="CalendarMonth">
                    
                        { Month }
                    
                    </div>

                </div>

                <div className="CalendarDates">
                
                    { Dates }
                
                </div>
            
            </div>

        );

    }

}

Calendar.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledDates: PropTypes.array,
    disableRedLetters: PropTypes.bool,
    disableWeekends: PropTypes.bool,
    limitLower: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    limitUpper: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    redLetters: PropTypes.array,
    selected:  PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] )

};

Calendar.defaultProps = {

    className: "",
    disabled: false,
    disabledDates: [],
    disableRedLetters: true,
    disableWeekends: false,
    limitLower: "",
    limitUpper: "",
    onChange: () => {},
    onClick: () => {},
    redLetters: [],
    selected: ""

};

export default Calendar;