/**!
 *  Conversation widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./conversation.scss";
import Fuse from "Class/Fuse";
import {RandomToken} from "Functions";
import CommentItem from "Components/UI/CommentItem";
import Spinner from "Components/Feedback/Spinner";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetConversation extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Join the Conversation",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            community:
            {
                label: "Filter from Community",
                placeholder: "Search for community...",
                type: "content",
                types: ["community"]
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.LoadToken = false;
        this.Mounted = false;
        this.Name = "Conversations";
        this.state =
        {
            comments: [],
            community: 0,
            error: false,
            loading: false
        };
    }

    componentDidMount()
    {
        this.Mounted = true;
        const {content} = this.props;
        const {community} = content;
        const [C] = community || [];
        const Id = typeof C === "object" ? C[0] : C || 0;
        this.Load(Id);
    }

    componentDidUpdate()
    {
        const {content} = this.props;
        const {community: c} = this.state;
        const {community} = content;
        const [C] = community || [];
        const Id = typeof C === "object" ? C[0] : C || 0;
        if (Id !== c)
        {
            this.Load(Id);
        }
    }

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    Comment = (comment) =>
    {
        const {id} = comment;
        return <CommentItem
            className="WidgetConversationComment"
            comment={comment}
            key={id}
        />;
    }

    Load = (community) =>
    {
        const Endpoint = community ? `communities/${community}/buzzing` : "buzzing";
        this.setState({community, loading: true});
        const Token = this.LoadToken = RandomToken();
        Fuse.Request(Endpoint, {}, response =>
        {
            if (!this.Mounted || Token !== this.LoadToken)
            {
                return;
            }
            const {comments} = response || {};
            this.setState({comments, error: !comments, loading: false});
        });
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover} = this.props;
        const {comments, loading} = this.state;
        const CA = ["Widget", "WidgetConversation", "White"];
        const Empty = !comments || !comments.length;
        const Content = [];
        if (active)
        {
            CA.push("Active");   
        }
        if (hover)
        {
            CA.push("Hover");   
        }
        if (loading)
        {
            CA.push("Loading");   
            Content.push(<Spinner
                className="WidgetSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (Empty)
        {
            Content.push(<div   
                className="WidgetEmpty"
                key="empty"
            >No comments</div>);
        }
        else
        {
            const Comments = [];
            comments.forEach(comment =>
            {
                Comments.push(this.Comment(comment));
            });
            Content.push(<div
                className="WidgetConversationComments"
                key="comments"
            >{Comments}</div>);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper {...WidgetContent}className="WidgetConversationWrapper">
                    {Content}
                </WidgetWrapper>
            </div>
       );
   }
}

export default WidgetConversation;