
/*!
 *  Textarea form field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value. 
 *  @prop string id - Field ID.
 *  @prop boolean insert - Whether to display injectable vars.
 *  @prop string label - Field label.
 *  @prop integer maxRows - Optional maximum number of visible rows.
 *  @prop function onAdjust - Callback for when the field height changes.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop boolean readOnly - Whether the field should be read-only.
 *  @prop string value - Field value.
 *  @prop integer visibleRows - The minimum amount of rows.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./textareafield.scss";

import { RandomToken } from "Functions";

import Insert from "Components/UI/Insert";

class TextareaField extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            focus: false,
            value: ""

        };

        this.DefaultLineHeight = 20;
        this.FocusValue = false;
        this.Token = RandomToken();

    }

    /**
     * Set initial value and add listeners on mount.
     * 
     * @return void
     */

    componentDidMount() {

        const { value } = this.props;

        this.setState( { value } );

        setTimeout( this.Adjust, 0 );

        window.addEventListener( "resize", this.Adjust );

    }

    /**
     * Update value.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { value } = nextProps;

        if ( value !== this.props.value ) {
            
            this.setState( { value } );

            setTimeout( this.Adjust, 0 );

        }

    }

    /**
     * Remove listeners on unmount.
     * 
     * @return void
     */

    componentWillUnmount() {

        window.removeEventListener( "resize", this.Adjust );

    }

    /**
     * Adjust the height of the field to fit its entire contents.
     * 
     * @return void
     */

    Adjust = () => {

        const { id, maxRows, onAdjust, visibleRows } = this.props;
        const Input = this.refs.input;

        if ( !Input ) {

            return;

        }

        const Styles = window.getComputedStyle( Input );
        
        let LineHeight = parseInt( Styles.lineHeight, 10 );

        if ( isNaN( LineHeight ) ) {

            LineHeight = this.DefaultLineHeight;

        }

        Input.style.height = LineHeight + "px";

        const Height = Input.offsetHeight;
        const Delta = Height - LineHeight;
        const NewHeight = Input.scrollHeight - Delta;
        const MinHeight = Math.max( NewHeight, LineHeight * visibleRows );
        const MaxHeight = maxRows ? Math.min( MinHeight, LineHeight * maxRows ) : MinHeight;

        Input.style.height = MaxHeight + "px";

        onAdjust( id );

    }

    /**
     * Set focus on this field.
     * 
     * @return void
     */

    Focus = () => {

        const { input } = this.refs;

        if ( input ) {

            input.focus();

        }

    }

    /*
     * Convert linebreaks to br-tags.
     *
     * @param string str - The unparsed string.
     * 
     * @return array - Array of parsed JSX.
     */

    Ln2Br = ( str ) => {

        if ( typeof str !== "string" ) {

            return str;

        }

        const Parsed = [];
        const Lines = str.split( /[\n]/ );

        Lines.forEach( ( line, index ) => {

            if ( index ) {
                
                Parsed.push( <br key={ index } /> );

            }
            
            Parsed.push( line );

        } );

        return Parsed;

    }

    /**
     * Callback for when the field loses focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnBlur = (e) => {

        const { id, onBlur, onChange } = this.props;
        const Value = e.currentTarget.value;

        if ( this.FocusValue !== Value ) {

            onChange( e, Value, id );

        }

        onBlur( e, id, this.refs.input );

        this.setState( { focus: false } );

    }

    /**
     * Callback for when the fields value changes.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnChange = (e) => {

        const { id, onChange } = this.props;
        const Value = e.currentTarget.value;

        onChange( e, Value, id );

        this.setState( { value: Value } );

    }

    /**
     * Callback for when the field gains focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnFocus = (e) => {

        const { id, onFocus } = this.props;
        this.FocusValue = e.currentTarget.value;

        onFocus( e, id, this.refs.input );

        this.setState( { focus: true } );

    }

    /**
     * Callback for when the users inputs a new value into the field.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnInput = (e) => {

        const { disabled, id, onInput } = this.props;
        const Input = this.refs.input;

        if ( !Input || disabled ) {

            return;

        }

        this.Adjust();

        const Value = Input.value;

        onInput( e, Value, id );

        this.setState( { value: Value } );

    }

    /**
     * Insert a variable key into the field.
     * 
     * @param object e - The event object.
     * @param string key - The variable key.
     * 
     * @return void
     */

    OnInsert = ( e, key ) => {

        const { id, onChange } = this.props;
        const { input } = this.refs;

        if ( !input ) {

            return;

        }

        const Notation = `@{${key}}`;
        const Value = input.value;

        // IE
        if ( document.selection ) {

            input.focus();

            const Selection = document.selection.createRange();

            Selection.text = Notation;

        }

        // Others
        else if ( input.selectionStart || input.selectionStart === "0" ) {

            const S = input.selectionStart;
            const E = input.selectionEnd;
            
            input.value = Value.substring( 0, S ) + Notation + Value.substring( E, Value.length );
            input.selectionStart = input.selectionEnd = S + Notation.length;
            input.focus();

        }

        const Set = input.value;

        onChange( e, Set, id );

        this.setState( { value: Set } );

    }

    /**
     * Stop key down events from propagating to avoid unintentional navigation.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyDown = (e) => {

        e.stopPropagation();

    }

    /**
     * Reset to inital state.
     * 
     * @return void
     */

    Reset = () => {

        const { id, onChange, value } = this.props;

        this.setState( { value } );

        onChange( null, value, id );

    }

    /**
     *   Get the field value.
     *   
     *   @return string - The field value.
     */

    Value = () => {

        return this.state.value;

    }

    render() {

        const { className, disabled, error, insert, label, placeholder, readOnly } = this.props;
        const { focus, value } = this.state;
        const CA = [ "Field", "TextareaField" ];

        if ( className ) CA.push( className );
        if ( disabled ) CA.push( "Disabled" );
        if ( error ) CA.push( "Error" );
        if ( focus ) CA.push( "Focus" );
        if ( insert ) CA.push( "HasInsert" );
        if ( readOnly ) CA.push( "ReadOnly" );
        if ( value ) CA.push( "HasValue" );

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                { label ? <label htmlFor={ this.Token }>{ label }</label> : "" }

                <div className="InputWrapper">
            
                    { readOnly ? <div className="Input">
                    
                        { this.Ln2Br( value ) }
                        
                    </div> : <textarea

                        className="Input"
                        disabled={ disabled }
                        id={ this.Token }
                        onBlur={ this.OnBlur }
                        onChange={ () => {} }
                        onFocus={ this.OnFocus }
                        onInput={ this.OnInput }
                        onKeyDown={ this.OnKeyDown }
                        placeholder={ placeholder }
                        ref="input"
                        value={ value }

                    /> }

                    { insert ? <Insert
                    
                        className="TextareaFieldInsert"
                        onInsert={ this.OnInsert }
                    
                    /> : "" }

                </div>
            
            </div>

        );

    }

}

TextareaField.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    insert: PropTypes.bool,
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    maxRows: PropTypes.number,
    onAdjust: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    visibleRows: PropTypes.number

};

TextareaField.defaultProps = {

    className: "",
    disabled: false,
    error: false,
    id: "",
    insert: false,
    label: "",
    maxRows: 0,
    onAdjust: () => {},
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onInput: () => {},
    placeholder: "",
    readOnly: false,
    value: "",
    visibleRows: 2

};

export default TextareaField;