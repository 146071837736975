/**!
 *  User learning plans widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./learningplans.scss";
import Fuse from "Class/Fuse";
import {ArrayClone, DateMonth, RandomToken, DateParse} from "Functions";
import ContentItem from "Components/UI/ContentItem";
import Progress from "Components/Feedback/Progress";
import SelectField from "Components/UI/Field/SelectField";
import Slider from "Components/UI/Slider";
import Spinner from "Components/Feedback/Spinner";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetLearningPlans extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Learning Plans",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            community:
            {
                label: "Filter from Community",
                placeholder: "Search for community...",
                type: "content",
                types: ["community"]
            },
            showFilter:
            {
                default: true,
                label: "Show filter",
                type: "checkbox"
            },
            lpGrayscale:
            {
                label: "Make Learning Plans grayscale",
                type: "checkbox",
                default: false
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Filters =
        {
            in_progress: "In progress",
            not_started: "Not started",
            completed: "Completed",
            with_deadline: "With deadline"
        };
        this.LoadToken = false;
        this.Mounted = false;
        this.Name = "Learning plans";
        this.state =
        {
            community: 0,
            done: false,
            error: false,
            filter: "",
            learningPlans: [],
            loading: false,
            page: 0,
            width: 324
        };
    }

    componentDidMount()
    {
        const {content} = this.props;
        const {community: c} = content || {};
        const Community = (c && c.length) ? c[0][0] : 0;
        this.Mounted = true;
        this.Load("", Community);
        this.Adjust();
        window.addEventListener("resize", this.Adjust);
    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.Adjust);
        this.Mounted = false;
    }

    /**
     * Update community filter.
     * @return void
     */

    componentDidUpdate()
    {
        const {content} = this.props;
        const {filter, community} = this.state;
        const {community: c} = content || {};
        const Community = (c && c.length) ? c[0][0] : 0;
        if (Community !== community)
        {
            this.Load(filter, Community);
        }
    }

    Adjust = () =>
    {
        if (!this.RefWidget)
        {
            return;
        }
        this.setState({width: 150 + Math.round(this.RefWidget.offsetWidth / 6.8391)});
    }

    Load = (filter = "", community = 0) =>
    {
        const {
            community: currentCommunity,
            filter: currentFilter,
            learningPlans,
            page
        } = this.state;
        const Reset = filter !== currentFilter || community !== currentCommunity;
        const Page =  Reset ? 1 : page || 1;
        const LearningPlans = Reset ? [] : learningPlans;
        const Token = this.LoadToken = RandomToken();
        const Endpoint = community ? `communities/${community}/learning_plans` : "learning-plans/my";
        this.setState({
            community,
            error: false,
            filter,
            learningPlans: LearningPlans,
            loading: true
        });
        Fuse.Request(Endpoint, filter ? {filter, page: Page} : {page: Page}, response =>
        {
            if (!this.Mounted || Token !== this.LoadToken)
            {
                return;
            }
            const {last_page, learning_plans} = response || {};
            if (!learning_plans)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                this.setState({
                    done: last_page,
                    learningPlans: ArrayClone(LearningPlans).concat(learning_plans),
                    loading: false,
                    page: Page + 1
                });
            }
        });
    }

    /**
     * Change content filter.
     * @param object e - The event object.
     * @param string filter - Content type.
     * @return void
     */

    SetFilter = (e, filter) =>
    {
        const {community} = this.state;
        const Filter = filter === -1 ? "" : filter;
        if (Filter === this.state.filter)
        {
            return;
        }
        this.Load(Filter, community);
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover, rowHeight} = this.props;
        const {filter, learningPlans, loading, width} = this.state;
        const {lpGrayscale, showFilter, title} = WidgetContent;
        const CA = [ "Widget", "WidgetLearningPlans", "White" ];
        const Content = [];
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover");
        }
        if (!learningPlans.length && loading)
        {
            CA.push("Loading");
            Content.push(<Spinner
                className="WidgetLearningPlansSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (learningPlans.length)
        {
            const Items = [];
            learningPlans.forEach(learningPlan =>
            {
                const {id, expiration_info, progress, thumbnail_3x_url, title} = learningPlan
                const {deadline_date} = expiration_info || {};
                const Deadline = deadline_date ? DateMonth(deadline_date) : "";
                // When filtering from a community, the endpoint doesn't take
                // notice of the filter, so we do this filtering at render.
                if (filter === "in_progress" && (progress === 0 || progress === 100))
                {
                    return;
                }
                if (filter === "not_started" && progress !== 0)
                {
                    return;
                }
                if (filter === "completed" && progress !== 100)
                {
                    return;
                }
                if (filter === "with_deadline" && !Deadline)
                {
                    return;
                }
                Items.push(
                    <div className="WidgetLearningPlansItem" key={id}>
                        <ContentItem
                            content={{grayscale: lpGrayscale}}
                            contentObject={{
                                id,
                                name: title,
                                preview: thumbnail_3x_url,
                                type: "learning-plan"
                            }}
                            width={width}
                            height={270 * rowHeight}
                            tag={Deadline ? `Due ${Deadline}` : ""}
                        >
                            <Progress
                                className="ContentItemProgress"
                                progress={progress / 100}
                            />
                        </ContentItem>
                    </div>   
                );
            });
            if (Items.length)
            {
                Content.push(<Slider 
                    className="WidgetLearningPlansItems"
                    key="items"
                    style={{height: 280 * rowHeight}}
                >{Items}</Slider>);
            }
        }
        if (!Content.length)
        {
            Content.push(<div
                className="WidgetEmpty"
                key="empty"
            >No learning plans</div>);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper
                    {...WidgetContent}
                    className="WidgetLearningPlansWrapper"
                    title={title}
                    toolbar={showFilter ? <SelectField
                        className="WidgetLearningPlansFilter"
                        onChange={this.SetFilter}
                        options={this.Filters}
                        placeholder="All Learningplans"
                        value={filter}
                    /> : "" } 
                >
                    <div className="WidgetLearningPlansAlign">
                        {Content}
                    </div>
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetLearningPlans;