
/**!
 *  Widget draft versions overview.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./draftsoverview.scss";

import Globals from "Class/Globals";
import { CanForEach, DateTimeStamp } from "Functions";

import Button from "Components/UI/Button";
import ScrollView from "Components/UI/ScrollView";
import User from "Components/UI/User";

class DraftsOverview extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            local: -1,
            selected: -1,
            userNames: []

        };

    }

    componentDidMount() {

        const { drafts } = this.props;
        let Local = -1;
        let Selected = -1;

        if ( CanForEach( drafts ) ) {

            drafts.forEach( ( draft, index ) => {

                if ( draft.active ) Selected = index;
                if ( draft.local ) Local = index;

            } );

        }

        this.setState( {

            local: Local,
            selected: Selected

        } );

    }

    /**
     * Render a draft item
     * 
     * @param object draft - Draft data object.
     * @param integer index - Index in overview.
     * 
     * @return JSX - Item element.
     */

    Item = ( draft, index ) => {

        const { userNames, selected } = this.state;
        const { id, own, updated, user, local } = draft;
        const CA = [ "DraftsOverviewItem" ];
        const Updated = DateTimeStamp( new Date( updated ), true );

        if ( local ) {

            CA.push( "Local" );

        }

        if ( own ) {

            CA.push( "Own" );

        }

        if ( selected === index ) {

            CA.push( "Selected" );

        }

        const CS = CA.join( " " );
        let UserName;

        if ( own || id === undefined ) {

            UserName = "you";

        }

        else if ( userNames[ index ] === undefined ) {

            UserName = `user #${user}`;

        }

        else {

            UserName = userNames[ index ];

        }

        const Name = local ? `Local draft created by ${UserName}` : `Public draft created by ${UserName}`;

        return (

            <div
            
                className={ CS }
                key={ index }
                onClick={ e => this.OnSelect( e, index ) }
                
            >

                <User
                
                    className="ItemPreview DraftsOverviewItemUser"
                    onLoad={ ( userId, userObject ) => { if ( !own ) this.OnLoadUser( index, userObject ) }}
                    user={ user }
                
                />

                <div className="ItemContent DraftsOverviewItemContent">

                    <div className="ItemName DraftsOverviewItemName">{ Name }</div>
                    <div className="ItemInfo DraftsOverviewItemInfo">{ `Updated at ${Updated}` }</div>

                </div>

            </div>

        );

    }

    /**
     *  Remove all draft version after confirmation.
     *  
     *  @return void.
     */

    OnClear = () => {

        const { id, onClear } = this.props;

        onClear( null, id );

    }

    /**
     *  Callback when the close button is clicked.
     *  
     *  @return void.
     */

    OnClose = () => {

        const { id, onClose } = this.props;

        onClose( id );

    }

    /**
     *  Callback when the load button is clicked.
     *  
     *  @return void.
     */

    OnLoad = (e) => {

        const { drafts, id, onLoad } = this.props;
        const { selected, local } = this.state;
        
        if ( drafts[ selected ] === undefined ) {

            return;

        }

        if ( local < 0 || local === selected ) {

            onLoad( e, drafts[ selected ], id );

        }

        else {

            Globals.DialogCreate( {

                confirmLabel: "View draft",
                message: "Viewing this public draft will clear your local draft. Do you want to continue?",
                onConfirm: () => onLoad( null, drafts[ selected ], id ),
                title: "Clear local draft version",
                type: "confirm"

            } );

        }

    }

    /**
     * Fetch the name of a user when its' draft loads.
     * 
     * @param integer index - Index in overview.
     * @param object user - User data.
     * 
     * @return void
     */

    OnLoadUser = ( index, user ) => {

        const { userNames } = this.state;

        userNames[ index ] = user.name;

        this.setState( { userNames } );

    }

    /**
     *  Callback when a draft item is clicked.
     * 
     *  @param object e - The event object.
     *  @param integer index - Index in overview.
     *  
     *  @return void.
     */

    OnSelect = ( e, index ) => {

        this.setState( {

            selected: index

        } );

    }

    render() {

        const { selected } = this.state;
        const { className, drafts } = this.props;
        const CA = [ "DraftsOverview" ];

        if ( className ) {

            CA.push( className );

        }

        const Items = []

        if ( CanForEach( drafts ) ) {

            drafts.forEach( ( draft, index ) => {

                Items.push( this.Item( draft, index ) );

            } );

        }

        if ( !Items.length ) {

            CA.push( "Empty" );

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                <div className="DraftsOverviewContent">

                    <ScrollView className="DraftsOverviewItems">

                        { Items }

                    </ScrollView>

                </div>

                <div className="DraftsOverviewTray">

                    <Button
                    
                        disabled={ selected < 0 }
                        label="View"
                        onClick={ this.OnLoad }
                        title="View this draft version of the widget"
                    
                    />

                    <Button
                    
                        hollow={ true }
                        label="Clear drafts"
                        onClick={ this.OnClear }
                    
                    />

                    <Button
                    
                        hollow={ true }
                        label="Close"
                        onClick={ this.OnClose }
                    
                    />

                </div>

            </div>

        );

    }

}

DraftsOverview.propTypes = {

    className: PropTypes.string,
    drafts: PropTypes.array,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    onClear: PropTypes.func,
    onClose: PropTypes.func,
    onLoad: PropTypes.func

};

DraftsOverview.defaultProps = {

    className: "",
    drafts: [],
    id: "",
    onClear: () => {},
    onClose: () => {},
    onLoad: () => {}

};

export default DraftsOverview;