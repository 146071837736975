/**!
 *  User activities widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./useractivities.scss";
import API from "Class/API";
import Fuse from "Class/Fuse";
import {TimeSince} from "Functions";
import Error from "Components/Feedback/Error";
import Link from "Components/UI/Link";
import Spinner from "Components/Feedback/Spinner";
import User from "Components/UI/User"
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetUserActivities extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "User Activities",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            community:
            {
                label: "Community",
                placeholder: "Search for community...",
                type: "content",
                types: ["community"]
            },
            communityNotice:
            {
                displayIf: ["community", "==", 0],
                label: "If no community is specified, the user activities in the current community will be loaded.",
                type: "notice"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Mounted = false;
        this.Name = "Leaderboard";
        this.state =
        {
            activities: [],
            comments: {},
            contentNames: {},
            done: false,
            error: false,
            loading: false,
            userNames: {}
        };
    }

    /**
     * Load user activities on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        this.Reload();
    }

    /**
     * Reload user activities if the community is changed.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content: ct1} = this.props;
        const {content: ct2} = prevProps;
        const {community: cm1} = ct1 || {};
        const {community: cm2} = ct2 || {};
        const C1 = (cm1 && cm1.length) ? cm1[0][0] : 0;
        const C2 = (cm2 && cm2.length) ? cm2[0][0] : 0;
        if (C1 !== C2)
        {
            this.Load(C1);
        }
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Get a comment.
     * @param integer commentId - Comment id.
     * @param integer contentId - Comment id.
     * @return string - Comment or placeholder.
     */

    Comment = (commentId, contentId) =>
    {
        const {comments} = this.state;
        const Key = contentId + "." + commentId;
        if (!comments[Key])
        {
            comments[Key] = "Loading...";
            Fuse.Comment(contentId, commentId, comment =>
            {
                if (!comment || !this.Mounted)
                {
                    return;
                }
                const {comments} = this.state;
                comments[Key] = comment.body;
                this.setState({comments});
            });
        }
        return comments[Key];
    }

    /**
     * Get a content name
     * @param integer id - Content id.
     * @return string - Content name or placeholder.
     */

    ContentName = (id) =>
    {
        const {contentNames} = this.state;
        if (!contentNames[id])
        {
            contentNames[id] = "[Loading...]";
            Fuse.Content(id, "", content =>
            {
                if (!content || !this.Mounted)
                {
                    return;
                }
                const {contentNames} = this.state;
                contentNames[id] = content.name;
                this.setState({contentNames});
            });
        }
        return contentNames[id];
    }

    /**
     * Output an activity item.
     * @param array item - [timestamp, user, action/commentId, contentId]
     * @param integer index - Item index.
     * @return JSX - The item.
     */

    Item = ([time, userId, action, contentId], index) =>
    {
        let Action = "";
        let ActionStr;
        let More = "";
        let Title = "";
        const Since = TimeSince(time);
        switch (action)
        {
            case "like":
            case "liked":
                ActionStr = this.UserName(userId) + " liked " + this.ContentName(contentId);
                Title = `${Since} ${ActionStr}`;
                Action = (
                    <Link
                        className="CommentItemTitleLink"
                        href={Fuse.Url(`contents/${contentId}`, false)}
                        target="_top"
                        title={Title}
                    >
                        {ActionStr}
                    </Link>
                );
                break;
            case "share":
            case "shared":
                ActionStr = this.UserName(userId) + " shared " + this.ContentName(contentId);
                Title = `${Since} ${ActionStr}`;
                Action = (
                    <Link 
                        className="CommentItemTitleLink"
                        href={Fuse.Url(`contents/${contentId}`, false)}
                        target="_top"
                        title={Title}
                    >
                        {ActionStr}
                    </Link>
                );
                break;
            default:
                More = this.Comment(action, contentId);
                ActionStr = this.UserName(userId) + " commented on " + this.ContentName(contentId);
                Title = `${Since} ${ActionStr}: ${More}`;
                Action = (
                    <Link
                        className="CommentItemTitleLink"
                        href={Fuse.Url(`contents/${contentId}`, false)}
                        target="_top"
                        title={Title}
                    >
                        {ActionStr}
                    </Link>
                );
                break;
        }
        return (
            <div className="WidgetUserActivitiesItem" key={index}>
                <User
                    className="ItemPreview WidgetUserActivitiesItemAvatar"
                    onLoad={this.OnLoadUser}
                    user={userId}
                />
                <div className="ItemContent WidgetUserActivitiesItemContent" title={Title}>
                    <div className="ItemTitle WidgetUserActivitiesItemAction">{Action}</div>
                    <div className="ItemInfo WidgetUserActivitiesItemSince">{Since}</div>
                    <div className="ItemInfo WidgetUserActivitiesItemMore">{More}</div>
                </div>
            </div>
        );
    }

    /**
     * Load user activities
     * @param integer community - Community id.
     * @return void
     */

    Load = (community = 0) =>
    {
        const {context, contextId} = this.props;
        const Current = context === "community" ? contextId : 0;
        const Community = community || Current;
        this.setState({
            activities: [],
            error: false,
            loading: true
        });
        API.Request("engagements/activities", {community: Community}, response =>
        {
            const {activities, error} = response;
            if (!activities || error)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                this.setState({activities, loading: false});
            }
        });
    }

    /**
     * Catch a users name when an item is loaded.
     * @param integer id - Users' id.
     * @param object data - Users' data.
     * @return void
     */

    OnLoadUser = (id, data) =>
    {
        const {userNames} = this.state;
        userNames[id] = data.name;
        this.setState({userNames});
    }

    /**
     * Reoad user activities.
     * @param integer community - Community id.
     * @return void
     */

    Reload = () =>
    {
        const {content} = this.props;
        const {community: c} = content || {};
        const Community = (c && c.length) ? c[0][0] : 0;
        this.Load(Community);
    }

    /**
     * Get a user name.
     * @param integer id - Users' id.
     * @return string - Users' name or placeholder.
     */

    UserName = (id) =>
    {
        const {userNames} = this.state;
        return userNames[id] || "[Loading...]";
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover} = this.props;
        const {activities, error, loading} = this.state;
        const {title} = WidgetContent;
        const CA = this.ClassNames(["WidgetUserActivities"]);
        const Content = [];
        if (active)
        {
            CA.push("Active");   
        }
        if (hover)
        {
            CA.push("Hover");   
        }
        if (loading)
        {
            CA.push("Loading");
            Content.push(<Spinner
                className="WidgetSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (error)
        {
            CA.push("Error");
            Content.push(<Error
                button="Try again"
                className="WidgetError"
                key="error"
                label="Unable to load user activities"
                onClick={this.Reload}
            />);
        }
        else
        {
            const Items = [];
            activities.forEach((activity, index) =>
            {
                Items.push(this.Item(activity, index));
            });
            Content.push(<div
                className="WidgetUserActivitiesItems"
                key="items"
            >
                {Items}
            </div>);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper
                    {...WidgetContent}
                    className="WidgetUserActivitiesWrapper"
                    title={title}
                >
                    {Content}
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetUserActivities;