
import React from "react";

import Broadcast from "Class/Broadcast";
import Globals from "Class/Globals";
import Fuse from "Class/Fuse";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ViewDialog from "Components/View/Dialog";
import ViewEditor from "Components/View/Editor";
import ViewNavigation from "Components/View/Navigation";
import ViewWidget from "Components/View/Widget";
import API from "../../Class/API";

class Core extends React.Component {

    constructor( props ) {

        super( props );

        this.Router = false;
        this.Touch = "ontouchstart" in window;

    }

    /**
     * Broadcast on mount.
     * 
     * @return void
     */

    componentDidMount() {

        Broadcast.SendMessage( { type: "load" } );

    }

    /**
     * Set title on navigation.
     * Scroll up on forward navigation.
     * 
     * @return void
     */

    OnNavigation = ( location ) => {

        const Path = location.pathname;

        // Set global path var.
        Globals.Var( "path", Path.length > 1 ? Path.substr(1) : Path );

        if ( location.action === "POP" ) {

            return;

        }

        window.scrollTo( 0, 0 );

    }

    /**
     * Add navigation listener on Router mount.
     * 
     * @return void
     */

    OnRouter = ( router ) => {

        if ( !router ) {

            return;

        }

        const Path = router.history.location.pathname;

        // Set global path var.
        Globals.Var( "path", Path.length > 1 ? Path.substr(1) : Path );

        this.Router = router;

        router.history.listen( this.OnNavigation );

    }

    /**
     * Load editor
     * 
     * @return void
     */

    RouteEditor = ( routeProps ) => {

        const { context } = routeProps.match.params;

        this.SetContext( context );

        return <ViewEditor />;

    }

    /**
     * Load requested widget.
     * 
     * @return void
     */

    RouteWidget = ( routeProps ) => {

        const { context, name } = routeProps.match.params;
        const [ ContextName, ContextId ] = this.SetContext( context );

        return <ViewWidget
        
            context={ ContextName }
            contextId={ ContextId }
            name={ name }
            
        />;

    }

    /**
     * Extract and set context + host.
     * 
     * @param string context - Unparsed context.
     * 
     * @return array - [ ContextName, ContextId, Host ];
     */

    SetContext = ( context ) => {

        const Context = context ? atob( context ).split( "__" ) : [];
        const [ ContextName, ContextId, Host, Location ] = Context || [];

        API.SetHost( Host );
        Globals.Var( "location", Location );
        Fuse.SetHost( Host );
        Broadcast.SetFuseHost( Host );

        return [ ContextName, ContextId, Host ];

    }

    render() {

        const CA = [ "Core" ];

        if ( this.Touch ) {

            CA.push( "Touch" );

        }

        else {

            CA.push( "NoTouch" );

        }
        
        const CS = CA.join( " " );

        return (

            <Router
        
                ref={ this.OnRouter }
                
            >

                <div className={ CS }>

                    <ViewDialog />

                    <Switch>

                        <Route path="/editor/:context" render={ this.RouteEditor } />
                        <Route path="/widget/:name/:context" render={ this.RouteWidget } />
                        <Route component={ ViewNavigation } />

                    </Switch>

                </div>

            </Router>

        );

    }

}

export default Core;