/*!
 *  Icon Item.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the item should be disabled.
 *  @prop string feather - Icon src.
 *  @prop string href - Optional link href.
 *  @prop string id - Item ID.
 *  @prop string label - Item label. Overridden by component children.
 *  @prop boolean loading - Whether to display the spinner instead of the icon.
 *  @prop function onClick - Callback for when the item is clicked.
 *  @prop string target - Optional. Link target window when a href has been specified.
 *  @prop string title - The item title tag.
 *  @prop string to - URI path when used for internal navigation.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./iconitem.scss";
import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";
import Spinner from "Components/Feedback/Spinner";

class IconItem extends React.Component
{
    render()
    {
        const {children, className, disabled, feather, label, loading, title} = this.props;
        const CA = ["IconItem"];
        if (loading)
        {
            CA.push("Loading");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <Link
                {...this.props}
                className={CA.join(" ")}
                disabled={disabled || loading}
                label={undefined}
                title={title || label}
            >
                {loading ? <Spinner size={18}/> : <Icon feather={feather}/>} 
                <div className="IconItemLabel">
                    {children || label}
                </div>     
            </Link>
        );
    }
}

IconItem.propTypes =
{
    className: PropTypes.string,
    label: PropTypes.string,
    loading: PropTypes.bool
};

IconItem.defaultProps =
{
    className: "",
    label: "",
    loading: false
};

export default IconItem;