/*!
 *  Number (integer) form field.
 *  @prop boolean big - Whether the field should be big.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string feather - Optional. Feather icon src.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onEnter - Callback for when the Enter key is pressed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop string token - Set a custom token identifier for this field.
 *  @prop string|number value - Field value.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import TextField from "Components/UI/Field/TextField";
class NumberField extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Focus = false;
        this.state =
        {
            value: 0
        };
    }

    /**
     * Set initial value.
     * @return void
     */

    componentDidMount()
    {
        const {maxValue, minValue, value} = this.props;
        this.SetValue(value, minValue, maxValue);
    }

    /**
     * Update value.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {maxValue: mx1, minValue: mn1, value: v1} = this.props;
        const {maxValue: mx2, minValue: mn2, value: v2} = prevProps;
        // Don't set a new value while the user edits the field.
        if (!this.Focus && (mx1 !== mx2 || mn1 !== mn2 || v1 !== v2))
        {
            this.SetValue(v1, mn1, mx1);
        }
    }

    /**
     * Callback for when the field loses focus.
     * @param object e - The event object.
     * @param integer value - The field value.
     * @return void
     */

    OnBlur = (e, value) =>
    {
        this.Focus = false;
        const {id, maxValue, minValue, onBlur} = this.props;
        const Value = this.SetValue(value, minValue, maxValue);
        onBlur(e, Value, id);
    }

    /**
     * Callback for when the field value changes.
     * @param object e - The event object.
     * @param integer value - The new value.
     * @return void
     */

    OnChange = (e, value) =>
    {
        const {id, maxValue, minValue, onChange} = this.props;
        const Value = this.ParseValue(value, minValue, maxValue);
        this.setState({value});
        onChange(e, Value, id);
    }

    /**
     * Callback for when the field gains focus.
     * @param object e - The event object.
     * @param integer value - The field value.
     * @return void
     */

    OnFocus = (e, value) =>
    {
        this.Focus = true;
        const {id, onFocus} = this.props;
        onFocus( e, value, id );
    }

    /**
     * Callback for when the user inputs a new value into the field.
     * @param object e - The event object.
     * @param integer value - The new value.
     * @return void
     */

    OnInput = (e, value) =>
    {
        const {id, maxValue, minValue, onInput} = this.props;
        const Value = this.ParseValue(value, minValue, maxValue);
        this.setState({value});
        onInput(e, Value, id);
    }

    /**
     * Parse the field value,
     * @param integer value - Unparsed value.
     * @param integer|boolean minValue - Optional minimum value.
     * @param integer|boolean maxValue - Optional maximum value.
     * @return integer - The parsed value.
     */

    ParseValue = (value, minValue, maxValue) =>
    {
        let Value = parseInt(value, 10);
        if (isNaN(Value))
        {
            Value = 0;
        }
        if (typeof minValue === "number" && Value < minValue)
        {
            Value = minValue;
        }
        else if (typeof maxValue === "number" && Value > maxValue)
        {
            Value = maxValue;
        }
        return Value;
    }

    /**
     * Update the field value,
     * @param integer value - Unparsed value.
     * @param integer|boolean minValue - Optional minimum value.
     * @param integer|boolean maxValue - Optional maximum value.
     * @return integer - The parsed value.
     */

    SetValue = (value, minValue, maxValue) =>
    {
        const Value = this.ParseValue(value, minValue, maxValue);
        this.setState({value: Value});
        return Value;
    }

    render()
    {
        const {className, id} = this.props;
        const {value} = this.state;
        const CA = ["NumberField"];
        if (className)
        {
            CA.push(className);
        }
        return (
            <TextField
                {...this.props}
                className={CA.join(" ")}
                id={id}
                onBlur={this.OnBlur}
                onFocus={this.OnFocus}
                onChange={this.OnChange}
                onInput={this.OnInput}
                value={value}
            />
        );
    }
}

NumberField.propTypes =
{
    className: PropTypes.string,
    maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

NumberField.defaultProps =
{
    className: "",
    id: "",
    maxValue: false,
    minValue: false,
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onInput: () => {},
    value: 0
};

export default NumberField;