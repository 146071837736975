
/**!
 *  404 Not Found.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./notfound.scss";

class ViewNotFound extends React.Component {
    
    render() {

        return (

            <div className="NotFound">

                <h2>404 Not Found</h2>

                <p>The view you requested doesn't exist.</p>

            </div>

        );

    }

}

export default ViewNotFound;