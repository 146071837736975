/**!
 *  Form fields.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./form.scss";
import Fuse from "Class/Fuse";
import {ArrayClone, DefaultValue, ObjectCompare, ParsedValue} from "Functions";
import Button from "Components/UI/Button";
import CheckboxField from "Components/UI/Field/CheckboxField";
import ChoiceField from "Components/UI/Field/ChoiceField";
import ColorField from "Components/UI/Field/ColorField";
import ConditionalWidgetField from "Components/UI/Field/ConditionalWidgetField";
import ConditionsField from "Components/UI/Field/ConditionsField";
import ContentField from "Components/UI/Field/ContentField";
import DateField from "Components/UI/Field/DateField";
import DateTimeField from "Components/UI/Field/DateTimeField";
import ImageField from "Components/UI/Field/ImageField";
import NumberField from "Components/UI/Field/NumberField";
import RepeaterField from "Components/UI/Field/RepeaterField";
import SelectField from "Components/UI/Field/SelectField";
import TextareaField from "Components/UI/Field/TextareaField";
import TextField from "Components/UI/Field/TextField";
import WidgetsField from "Components/UI/Field/WidgetsField";

class Form extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Block = false;
        this.state =
        {
            content: false
       };
   }

    /**
     * Set content on mount.
     * @return void
     */

    componentDidMount()
    {
        const {content} = this.props;
        const Content = ArrayClone(content);
        this.setState({content: Content});
    }

    /**
     * Update content when the prop updated.
     * @return void
     */

    componentDidUpdate()
    {
        const {content: c1} = this.props;
        const {content: c2} = this.state;
        if (!ObjectCompare(c1, c2) && !this.Block)
        {
            const Content = ArrayClone(c1);
            this.setState({content: Content});
        }
    }

    /**
     * Match conditions.
     * @param array conditions - Condition expression.
     * @param object content - Content to check conditions against.
     * @return boolean - Whether the conditions where met.
     */

    Conditions = (conditions, content) =>
    {
        if (!conditions || typeof conditions !== "object")
        {
            return true;
        }
        const AndOr = String(conditions[0]).toLowerCase();
        if (AndOr === "and" || AndOr === "or")
        {
            let Match = AndOr === "and";
            for (let i = 1; i < conditions.length; i++)
            {
                const M = this.Conditions(conditions[i], content);
                if (AndOr === "and" && !M)
                {
                    Match = false;
                    break;
                }
                else if (AndOr === "or" && M)
                {
                    Match = true;
                    break;
                }
            }
            return Match;
        }
        else
        {
            let [A, C, B] = conditions;
            let V1, V2;
            const Bstr = typeof B === "string" ? B.toLowerCase() : null;
            if (Bstr === "empty" || Bstr === "not empty")
            {
                V1 = Bstr === "empty";
                V2 = !content[A] || (Array.isArray(content[A]) && !content[A].length);
            }
            else
            {
                V1 = content[A] !== undefined ? content[A] : A;
                V2 = content[B] !== undefined ? content[B] : B;
            }
            if (typeof V1 === "object" && V1.length !== undefined) V1 = V1.length;
            if (typeof V2 === "object" && V2.length !== undefined) V2 = V2.length;
            switch (C)
            {
                case "<": return V1 < V2;
                case "<=": return V1 <= V2;
                case "==": return V1 === V2;
                case "===": return V1 === V2;
                case "!=": return V1 !== V2;
                case "!==": return V1 !== V2;
                case ">=": return V1 >= V2;
                case ">": return V1 > V2;
                default: return true;
            }
        }
    }

    /**
     * Callback when a button is clicked.
     * @param object e - The event object.
     * @param string key - Field (button) key.
     * @return void
     */

    OnButton = (e, key) =>
    {
        const {id, fields, onButton} = this.props;
        const {callback} = fields[key] || {};
        onButton(e, callback, id, key);
    }

    /**
     * Callback when a field label is requested.
     * 
     * @param object e - The event object.
     * @param object map - A map to the value within the item which to search for.
     * @param object|array item - The item value.
     * @param string id - The id of the request.
     * @param function callback - Callback when the label is determined.
     * 
     * @return void
     */

    OnLabel = (e, map, item, id, callback) =>
    {
        if (typeof map !== "object" || typeof callback !== "function")
        {
            return;
        }
        const Map = ArrayClone(map);
        const Type = Map.shift();
        let P; let V = ArrayClone(item);
        while (Map.length)
        {
            P = Map.shift();
            if (typeof V !== "object" || !V[P])
            {

                return;
            }
            V = V[P];
        }
        switch (Type)
        {
            case "content":
                callback(null, id, "Loading...");
                Fuse.ContentName(V, name =>
                {
                    callback(null, id, name);
                });
                break;
            default:
                return;
        }
    }

    /**
     * Update content field value.
     * @param object content - Unparsed content.
     * @return void
     */

    SetField = (e, value, key) =>
    {
        const {id, onEdit} = this.props;
        const {content} = this.state;
        const Content = ArrayClone(content);
        Content[key] = value;
        // Block componentDidUpdate when the changes comes from the component itself.
        this.Block = true;
        this.setState({content: Content}, () => this.Block = false);
        onEdit(e, key, value, id);
    }

    render()
    {
        const {className, disabled, fields, reserved} = this.props;
        const {content} = this.state;
        if (!content)
        {
            return "";
        }
        const CA = ["Form"];
        if (className)
        {
            CA.push(className);
        }
        const Content = ArrayClone(content);
        const Fields = [];
        for (let key in fields)
        {
            if (reserved.indexOf(key) >= 0)
            {
                console.error(`'${key}' is a reserved field key!`);
                continue;
            }
            let
            {
                addLabel,
                columns,
                default: defaultValue,
                displayIf,
                fields: subFields,
                gradient,
                hollow,
                insert,
                label,
                maxValue,
                minValue,
                multiple,
                nameKey,
                onLabel,
                options,
                placeholder,
                text,
                type,
                types
            } = fields[key];
            if (!this.Conditions(displayIf, Content))
            {
                continue;
            }
            let Disabled = typeof disabled === "object" ? disabled[key] : disabled;
            let Value = Content[key] = ParsedValue(content[key], type, defaultValue || DefaultValue(type));
            switch (type)
            {
                case "button":
                    Fields.push(
                        <Button
                            disabled={Disabled}
                            hollow={hollow}
                            id={key}
                            key={key}
                            label={label}
                            onClick={this.OnButton}
                        />
                    );
                    break;
                case "checkbox":
                    Fields.push(
                        <CheckboxField
                            checked={!!Value}
                            disabled={Disabled}
                            id={key}
                            key={key}
                            label={label}
                            onChange={this.SetField}
                            text={text}
                        />
                    );
                    break;
                case "choice":
                    Fields.push(
                        <ChoiceField
                            disabled={Disabled}
                            id={key}
                            key={key}
                            label={label}
                            onChange={this.SetField}
                            options={options}
                            value={Value}
                        />
                    );
                    break;
                case "color":
                    Fields.push(
                        <ColorField
                            disabled={Disabled}
                            id={key}
                            gradient={gradient}
                            key={key}
                            label={label}
                            onChange={this.SetField}
                            value={Value}
                        />
                    );
                    break;
                case "conditionalWidget":
                    Fields.push(
                        <ConditionalWidgetField
                            disabled={Disabled}
                            id={key}
                            key={key}
                            label={label}
                            onChange={this.SetField}
                            value={Value}
                        />
                    );
                    break;
                case "conditions":
                    Fields.push(
                        <ConditionsField
                            disabled={Disabled}
                            id={key}
                            key={key}
                            label={label}
                            onChange={this.SetField}
                            value={Value}
                        />
                    );
                    break;
                case "content":
                    Fields.push(
                        <ContentField
                            disabled={Disabled}
                            id={key}
                            key={key}
                            label={label}
                            multiple={multiple}
                            onChange={this.SetField}
                            placeholder={placeholder}
                            value={Value}
                            types={types}
                        />
                    );
                    break;
                case "date":
                    Fields.push(
                        <DateField
                            disabled={Disabled}
                            id={key}
                            key={key}
                            label={label}
                            onChange={this.SetField}
                            value={Value}
                        />
                    );
                    break;
                case "datetime":
                    Fields.push(
                        <DateTimeField
                            disabled={Disabled}
                            id={key}
                            key={key}
                            label={label}
                            onChange={this.SetField}
                            value={Value}
                        />
                    );
                    break;
                case "image":
                    Fields.push(
                        <ImageField
                            disabled={Disabled}
                            id={key}
                            key={key}
                            label={label}
                            multiple={multiple}
                            onChange={this.SetField}
                            value={Value}
                        />
                    );
                    break;
                case "notice":
                    Fields.push(
                        <div
                            className="FormNotice"
                            key={key}
                        >{label}</div>
                    );
                    break;
                case "number":
                    Fields.push(
                        <NumberField
                            disabled={Disabled}
                            id={key}
                            key={key}
                            label={label}
                            maxValue={maxValue}
                            minValue={minValue}
                            onChange={this.SetField}
                            placeholder={placeholder}
                            value={Value}
                        />
                    );
                    break;
                case "repeater":
                    Fields.push(
                        <RepeaterField
                            addLabel={addLabel}
                            disabled={Disabled}
                            id={key}
                            fields={subFields}
                            key={key}
                            label={label}
                            nameKey={nameKey}
                            onChange={this.SetField}
                            onLabel={onLabel ? (e, item, id, index, callback) =>
                            {
                                this.OnLabel(e, onLabel, item, index, callback);
                            } : () => {}}
                            value={Value}
                        />
                    );
                    break;
                case "select":
                    Fields.push(
                        <SelectField
                            disabled={Disabled}
                            id={key}
                            key={key}
                            label={label}
                            onChange={this.SetField}
                            options={options}
                            value={Value}
                        />
                    );
                    break;
                case "textarea":
                    Fields.push(
                        <TextareaField
                            disabled={Disabled}
                            id={key}
                            insert={insert}
                            key={key}
                            label={label}
                            onChange={this.SetField}
                            onInput={this.SetField}
                            placeholder={placeholder}
                            value={Value}
                        />
                    );
                    break;
                case "widgets":
                    const Columns = Content[columns] || columns || 6;
                    Fields.push(
                        <WidgetsField
                            disabled={Disabled}
                            id={key}
                            key={key}
                            columns={Columns}
                            label={label}
                            onChange={this.SetField}
                            value={Value}
                        />
                    );
                    break;
                default:
                    Fields.push(
                        <TextField
                            disabled={Disabled}
                            id={key}
                            insert={insert}
                            key={key}
                            label={label}
                            onChange={this.SetField}
                            onInput={this.SetField}
                            placeholder={placeholder}
                            value={Value}
                        />
                    );
            }
        }
        return <div className={CA.join(" ")}>{Fields}</div>;
    }
}

Form.propTypes =
{
    className: PropTypes.string,
    content: PropTypes.object,
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    fields: PropTypes.object,
    onButton: PropTypes.func,
    onEdit: PropTypes.func,
    reserved: PropTypes.array
};

Form.defaultProps =
{
    className: "",
    content: {},
    disabled: false,
    fields: {},
    onButton: () => {},
    onEdit: () => {},
    reserved: []
};

export default Form;