/*!
 *  Feather icon.
 *  @prop string className - Append a class name.
 *  @prop string feather - Feather icon name.
 *  @prop integer size - Icon size;
 *  @prop object style - Icon style attribute.
 *  @prop string title - The element title tag.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./icon.scss";
import * as FeatherIcon from "react-feather";

class Icon extends React.Component
{
    render()
    {
        const {className, feather, fill, size, style, title} = this.props;
        const CA = ["Icon"];
        if (className)
        {
            CA.push(className);
        }
        const Style = style || {};
        let Feather = feather;
        if (typeof FeatherIcon[Feather] === "undefined")
        {
            Feather = "Heart";
        }
        const IconComponent = FeatherIcon[Feather];
        const IconElement = React.createElement(IconComponent,{
            display: "block",
            width: "100%",
            height: "100%",
            strokeWidth: 1
        });
        if (fill) Style.color = fill;
        if (Style.width === undefined) Style.width = size;
        if (Style.height === undefined) Style.height = size;
        return <div className={CA.join(" ")} style={Style} title={title}>{IconElement}</div>;
    }
}

Icon.propTypes =
{
    className: PropTypes.string,
    feather: PropTypes.string,
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    title: PropTypes.string
};

Icon.defaultProps =
{
    className: "",
    feather: "Heart",
    fill: "",
    size: 18,
    style: false,
    title: ""
};

export default Icon;