
/*!
 *  Tab navigation.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./tabmenu.scss";

class TabMenu extends React.Component {

    /**
     * Output a tab menu item.
     * 
     * @param string|JSX item - Item contents.
     * @param string|integer key - Item key.
     * 
     * @return JSX - The tab menu item.
     */

    Item = ( item, key ) => {

        const { id, disabled, onClick, selected } = this.props;
        const CA = [ "TabMenuItem" ];
        const Disabled = disabled === true || ( typeof disabled === "object" && disabled[ key ] );

        if ( Disabled ) {

            CA.push( "Disabled" );

        }

        else if ( selected !== false && selected === key ) {

            CA.push( "Selected" );

        }

        const CS = CA.join( " " );

        return (

            <div
            
                className={ CS }
                key={ key }
                onClick={ e => {

                    if ( Disabled ) {

                        return;

                    }

                    onClick( e, key, id );

                } }
            
            >

                { item }

            </div>

        );

    }

    render() {

        const { className, items, vertical } = this.props;
        const CA = [ "TabMenu" ];

        if ( className ) {

            CA.push( className );

        }

        if ( vertical ) {

            CA.push( "Vertical" );

        }

        else {

            CA.push( "Horisontal" );

        }

        const CS = CA.join( " " );
        const Items = [];

        for ( var key in items ) {

            Items.push( this.Item( items[ key ], key ) );

        }

        return (

            <div className={ CS }>

                { Items }

            </div>

        );

    }

}

TabMenu.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.oneOfType( [ PropTypes.bool, PropTypes.array ] ),
    id: PropTypes.string,
    items: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] ),
    onClick: PropTypes.func,
    selected: PropTypes.oneOfType( [ PropTypes.number, PropTypes.string, PropTypes.bool ] ),
    vertical: PropTypes.bool

};

TabMenu.defaultProps = {

    className: "",
    disabled: false,
    id: "",
    items: [],
    onClick: () => {},
    selected: false,
    vertical: false

}

export default TabMenu;