
/**!
 *  Toggle button to be sent to the parent window.
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";

import Icon from "Components/Layout/Icon";

class ToggleButton extends React.Component {

    render() {

        const { className, size } = this.props;
        const CA = [ "ToggleButton" ];

        if ( className ) {

            CA.push( className );

        }

        const CS = CA.join( " " );
        const PaddingPercent = .2;
        const Padding = size * PaddingPercent;
        const Size = size * ( 1 - PaddingPercent * 2 );

        const Style = {

            position: "relative",
            width: Size,
            height: Size,
            padding: Padding,
            color: "#ffffff",
            backgroundColor: "#78153a",
            border: "2px solid #ffffff",
            borderRadius: size,
            cursor: "pointer"

        };

        const IconStyle = {

            position: "relative",
            width: Size,
            height: Size

        };

        return (

            <div className={ CS } style={ Style }><Icon
            
                feather="Edit2"
                style={ IconStyle }
            
            /></div>

        );

    }

}

ToggleButton.propTypes = {

    className: PropTypes.string,
    size: PropTypes.number

};

ToggleButton.defaultProps = {

    className: "",
    size: 60

}

export default ToggleButton;
