import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import Core from "Components/Core";
import { unregister as unregisterServiceWorker } from "serviceWorker.js";
import "main.scss";

ReactDOM.render( <Core />, document.getElementById( "root" ) );

unregisterServiceWorker();