/*!
 *  Internal/external link.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the link should be disabled.
 *  @prop string href - External URL.
 *  @prop string id - Link id used in callbacks.
 *  @prop string label - Link label.
 *  @prop function onClick - Callback for when the item is clicked.
 *  @prop object style - Style attributes.
 *  @prop string target - Optional. Link target window when a href has been specified.
 *  @prop string title - The item title tag.
 *  @prop string to - URI path when used for internal navigation.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./link.scss";
import Globals from "Class/Globals";
import {NavLink as RouterLink, withRouter} from "react-router-dom";

class Link extends React.Component
{
    OnClick = (e) =>
    {
        const {disabled, id, onClick} = this.props;
        if (disabled)
        {
            return;
        }
        if (onClick(e, id) === false)
        {
            e.stopPopagation();
            e.preventDefault();
            return false;
        }
    }

    OnMouseDown = (e) =>
    {
        const {disabled, id, onMouseDown} = this.props;
        if (disabled)
        {
            return;
        }
        onMouseDown(e, id);
    }

    OnMouseUp = (e) =>
    {
        const {disabled, id, onMouseUp} = this.props;
        if (disabled) 
        {
            return;
        }
        onMouseUp(e, id);
    }

    render()
    {
        let {       
            children,
            className,
            disabled,
            href,
            label,
            onMouseEnter,
            onMouseLeave,
            style,
            target,
            title,
            to
        } = this.props;
        const CA = ["Link"];
        if (href.match(/^\s*javascript/i)) href = target = "";
        else if (href.match( /^\s*mailto/i)) target = "_top";
        if (className)
        {
            CA.push(className);
        }
        if (disabled)
        {
            CA.push("Disabled");
        }
        if (to === Globals.Var("path"))
        {
            CA.push("Active");
        }
        if (disabled || (!to && !href))
        {
            return (
                <span
                    className={CA.join(" ")}
                    onClick={this.OnClick}
                    onMouseDown={this.OnMouseDown}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onMouseUp={this.OnMouseUp}
                    style={style}
                    title={title}
                >
                    {label || children}
                </span>
            );
        }
        else if (href)
        {
            return (
                <a             
                    rel="noopener noreferrer"
                    className={CA.join(" ")}
                    href={href}
                    onClick={this.OnClick}
                    onMouseDown={this.OnMouseDown}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onMouseUp={this.OnMouseUp}
                    style={style}
                    target={target}
                    title={title}
                >
                    {label || children}
                </a>
            );
        }
        else
        {
            return (   
                <RouterLink
                    className={CA.join(" ")}
                    exact={true}
                    onClick={this.OnClick}
                    onMouseDown={this.OnMouseDown}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onMouseUp={this.OnMouseUp}
                    style={style}
                    title={title}
                    to={to}
                >
                    {label || children}
                </RouterLink>
            );
        }
    }
}

Link.propTypes =
{
    className: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseUp: PropTypes.func,
    style: PropTypes.object,
    target: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string
};

Link.defaultProps =
{
    className: "",
    disabled: false,
    href: "",
    id: "",
    label: "",
    onClick: () => {},
    onMouseDown: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onMouseUp: () => {},
    style: {},
    target: "_blank",
    title: "",
    to: ""
};

export default withRouter(Link);