/**!
 *  Blank/empty widget.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./blank.scss";

class WidgetBlank extends Widget
{
    constructor (props)
    {
        super(props);
        this.Fields = this.SetFields();
        this.Name = "Blank";
    }

    render()
    {
        const CS = this.ClassNames(["WidgetBlank" ], true);
        return (
            <div className={CS} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                <div className="WidgetBlankContent"/>
            </div>
        );
    }
}

export default WidgetBlank;