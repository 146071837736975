
/*!
 *  Fuse content list item
 *
 *  @prop string className - Append a class name.
 *  @prop object content - Content object.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./contentlistitem.scss";

import API from "Class/API";
import Fuse from "Class/Fuse";
import { ObjectCompare } from "Functions";

import Link from "Components/UI/Link";
import Preview from "Components/Layout/Preview";

class ContentListItem extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            content: false,
            contentObject: false,
            error: false,
            loading: false

        };

    }

    /**
     * Load content on mount.
     * 
     * @return void
     */

    componentDidMount() {

        const { content, contentObject } = this.props;
        const { content: fuseContent } = content;

        if ( contentObject ) {

            this.setState( {
                
                content: contentObject.id,
                contentObject
                
            } );

        }

        else {

            this.Load( fuseContent );

        }

    }

    /**
     * Update content when a new ID is received.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { content, contentObject } = nextProps;
        const { content: currentContent, contentObject: currentContentObject } = this.state;
        const { content: fuseContent } = content;
        const NewObject = !ObjectCompare( contentObject, currentContentObject );

        let Content = fuseContent;

        while ( Content && typeof Content === "object" ) {

            Content = Content[0];

        }

        if ( contentObject && NewObject ) {

            this.setState( {
                
                content: contentObject.id,
                contentObject
                
            } );

        }

        else if ( !contentObject && !ObjectCompare( Content, currentContent ) ) {

            this.Load( fuseContent );

        }

    }

    /**
     * Load content from the Fuse API.
     * 
     * @param array|integer item - [ contentId, type ] or just contentId.
     * 
     * @return void
     */

    Load = ( item ) => {

        if ( !item ) {

            this.setState( {

                content: 0,
                error: false,
                loading: false

            } );

        }

        else if ( typeof item === "object" && typeof item[0] === "object" ) {

            this.Load( item[0] );

        }

        else if ( typeof item === "object" ) {

            this.setState( {

                content: item[0],
                error: false,
                loading: true

            } );

            this.LoadContent( item[0], item[1] );

        }

        else {

            this.setState( {

                content: item,
                error: false,
                loading: true

            } );
            
            /**
             * Check content type in the backend DB before fetching.
             */

            API.Request( "content/cache-read", { id: item }, response => {

                const { cache, error } = response;

                if ( error ) {

                    this.setState( {

                        error: true,
                        loading: false

                    } );

                }

                else {

                    this.LoadContent( item, cache.type );

                }

            } );

        }

    }

    /**
     * Load content data from fuse.
     * 
     * @param integer id - The content id.
     * @param string type - The content type.
     * 
     * @return void
     */

    LoadContent = ( id, type ) => {

        Fuse.Content( id, type, content => {

            if ( !content ) {

                this.setState( {

                    error: true,
                    loading: false

                } );

            }

            else {

                this.setState( {

                    loading: false,
                    contentObject: content

                } );

            }

        } );

    }

    render() {

        const { className, content } = this.props;
        const { content: contentId, contentObject, error, loading } = this.state;
        const { externalLink, image, label, url } = content;
        const CA = [ "ContentListItem" ];

        if ( className ) {

            CA.push( className );

        }

        if ( error && ( !externalLink || !url ) ) {

            CA.push( "ErrorItem" );

        }

        const CS = CA.join( " " );
        let PreviewUrl, Label, Url;
        let Description = "";
        let Target = "_top";
        let Type = "File";

        if ( loading ) {

            Description = "Loading...";
            PreviewUrl = image ? parseInt( image[0], 10 ) : 0;
            Label = label;

        }

        else if ( !contentId ) {

            Description = externalLink ? "External link" : "Link";
            PreviewUrl = image ? parseInt( image[0], 10 ) : 0;
            Label = label;
            Target = externalLink ? "_blank" : "_top";
            Type = "ExternalLink";
            Url = url;

        }

        else if ( contentObject ) {

            const { id, name, preview, raw, type } = contentObject;
            const { created_at } = raw || {};
            Description = `Posted ${created_at} ago`;
            PreviewUrl = ( typeof image === "object" && image.length ) ? parseInt( image[0], 10 ) : preview;
            Label = label || name;
            Type = type;
            Url = Fuse.ContentUrl( id, type );

        }
        
        return (

            <Link
            
                className={ CS }
                href={ Url }
                target={ Target }
                
            >

                <Preview
                
                    className="ItemPreview"
                    content={ Type }
                    image={ PreviewUrl }
                
                />

                <div className="ItemContent">

                    <div className="ItemName">{ Label }</div>
                    <div className="ItemInfo">{ Description }</div>

                </div>
                
            </Link>

        );

    }

}

ContentListItem.propTypes = {

    className: PropTypes.string,
    content: PropTypes.object,
    contentObject: PropTypes.oneOfType( [ PropTypes.object, PropTypes.bool ] )

};

ContentListItem.defaultProps = {

    className: "",
    content: {},
    contentObject: false

};

export default ContentListItem;