
/*!
 *  Injectable vars menu.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the menu should be disabled.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./insert.scss";

import Fuse from "Class/Fuse";

import IconButton from "Components/UI/IconButton";
import Sticky from "Components/Layout/Sticky";

class Insert extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            expand: false

        };

    }

    /**
     * Callback when the menu is expanded/collapsed.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    Expand = ( e ) => {

        const { id, onExpand } = this.props;
        const { expand } = this.state;
        const Expand = !expand;

        this.setState( {

            expand: Expand

        } );

        onExpand( e, Expand, id );

    }

    /**
     * Callback when a menu item is clicked.
     * 
     * @param object e - The event object.
     * @param string key - The insert variable key.
     * @param string value - The insert variable value in the current context.
     * 
     * @return void
     */

    Insert = ( e, key, value ) => {

        const { id, onInsert } = this.props;

        this.setState( {

            expand: false

        } );

        onInsert( e, key, value, id );

    }

    /**
     * Output a menu item
     * 
     * @param string [name] - The name/label of the variable.
     * @param string [value] - The insert variable value in the current context.
     * @param string key - The insert variable key.
     * 
     * @return JSX - The menu item.
     */

    Item = ( [ name, value ], key ) => {

        return (
        
            <div
            
                className="InsertMenuItem"
                key={ key }
                onClick={ e => this.Insert( e, key, value ) }
                
            >{ name }</div>

        );

    }

    render() {

        const { className, disabled, size, title } = this.props;
        const { expand } = this.state;
        const CA = [ "Insert" ];
        const Vars = Fuse.InjectVars();
        const Disabled = disabled || !Vars;
        const Expand = !Disabled && expand;
        const Items = [];

        if ( className ) {

            CA.push( className );

        }

        if ( Disabled ) {

            CA.push( "Disabled" );

        }

        else if ( Expand ) {

            CA.push( "Expand" );

            for ( let key in Vars ) {

                Items.push( this.Item( Vars[ key ], key ) );

            }

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                <IconButton
                
                    className="InsertButton"
                    disabled={ Disabled }
                    feather="AtSign"
                    onClick={ this.Expand }
                    size={ size }
                    title={ title }
                
                />

                { Expand ? <Sticky
                
                    align="right"
                    className="InsertMenu"
                    onClose={ this.Expand }
                
                >{ Items }</Sticky> : "" }

            </div>

        );

    }

}

Insert.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onExpand: PropTypes.func,
    onInsert: PropTypes.func,
    size: PropTypes.number,
    title: PropTypes.string

};

Insert.defaultProps = {

    className: "",
    disabled: false,
    id: "",
    onExpand: () => {},
    onInsert: () => {},
    size: 16,
    title: "Insert variable"

};

export default Insert;
