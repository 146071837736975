/**!
 *  User favourites widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./favourites.scss";
import Auth from "Class/Auth";
import Fuse from "Class/Fuse";
import {ArrayClone, RandomToken, StripTags} from "Functions";
import ContentTypeField from "Components/UI/Field/ContentTypeField";
import IconItem from "Components/UI/IconItem";
import Link from "Components/UI/Link";
import Preview from "Components/Layout/Preview";
import Spinner from "Components/Feedback/Spinner";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetFavourites extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Favourites",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            showFilter:
            {
                default: true,
                label: "Show content filter",
                type: "checkbox"
            }

        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.LoadToken = false;
        this.Mounted = false;
        this.Name = "Favourites";
        this.state =
        {
            content: [],
            done: false,
            filter: -1,
            error: false,
            loading: false,
            page: 0
        };
    }

    /**
     * Load content on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        this.Load();
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Output a content item.
     * @param object content - Content object.
     * @param integer index - Item index in list.
     * @return JSX - The content item.
     */

    Item = (content, index) =>
    {
        const {description, id, preview_path, title, type} = content.favourite;
        const Url = Fuse.Url(`contents/${id}`, false);
        const PreviewUrl = preview_path ? Fuse.Url(preview_path.substr(1), false) : "";
        return (
            <Link
                className="WidgetFavouritesItem"
                key={`${index}.${id}`}
                href={Url}
                target="_top"
            >
                <Preview
                    className="ItemPreview"
                    content={type}
                    image={PreviewUrl}
                />
                <div className="ItemContent">
                    <div className="ItemName">{title}</div>
                    <div className="ItemInfo">
                        {StripTags(description)}
                    </div>
                </div>
            </Link>
        )
    }

    /**
     * Load Favourites content from the Fuse API.
     * @return void
     */

    Load = () =>
    {
        const {content, page} = this.state;
        const {UserId} = Auth;
        const Page = page || 1;
        if (!UserId)
        {
            return;
        }
        const Token = this.LoadToken = RandomToken();
        this.setState({error: false, loading: true});
        Fuse.Request(`users/${UserId}/favourites`, {
            page: Page,
            swagger_request: "true"
        }, response =>
        {
            if (!this.Mounted || Token !== this.LoadToken)
            {
                return;
            }
            const {last_page, items} = response || {};
            if (!items)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                const Content = ArrayClone(content).concat(items);
                this.setState({
                    done: last_page,
                    content: Content,
                    loading: false,
                    page: Page + 1
                });
            }
        });
    }

    /**
     * Change content filter.
     * @param object e - The event object.
     * @param string filter - Content type.
     * @return void
     */

    SetFilter = (e, filter) =>
    {
        if (filter === this.state.filter)
        {
            return;
        }
        this.setState({filter});
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover} = this.props;
        const {content, filter, done, loading} = this.state;
        const {title, showFilter} = WidgetContent;
        const CA = ["Widget", "WidgetFavourites", "White"];
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover"); 
        }
        const Content = [];
        const Items = [];
        if (content && content.length)
        {
            content.forEach((c, i) =>
            {
                if (filter !== -1 && filter !== c.favourite.type)
                {
                    return;
                }
                Items.push(this.Item(c, i));
            });
        }
        if (!Items.length && loading)
        {
            Content.push(<Spinner
                className="WidgetSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (!Items.length)
        {
            Content.push(<div
                className="WidgetEmpty"
                key="empty"
            >No content</div>);
        }
        else
        {
            Content.push(<div
                className="WidgetFavouritesItems"
                key="items"
            >{Items}</div>);
            Content.push(<IconItem
                className="WidgetFavouritesButton"
                disabled={done}
                feather="PlusCircle"
                key="fetch"
                label="Show more"
                loading={loading}
                onClick={this.Load}
            />);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper
                    {...WidgetContent}
                    className="WidgetFavouritesWrapper"
                    title={title}
                    toolbar={showFilter ? <ContentTypeField
                        className="WidgetFavouritesFilter"
                        onChange={this.SetFilter}
                        placeholder="All Content"
                        value={filter}
                    /> : ""} 
                >
                    {Content}
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetFavourites;