
import React from "react";

import Auth from "Class/Auth";
import Globals from "Class/Globals";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ViewLogin from "Components/View/Login";
import ViewNotFound from "Components/View/NotFound";

class ViewNavigation extends React.Component {

    constructor( props ) {

        super( props );

        this.Router = false;
        this.Touch = "ontouchstart" in window;

    }

    /**
     * Set title on navigation.
     * Scroll up on forward navigation.
     * 
     * @return void
     */

    OnNavigation = ( location ) => {

        const Path = location.pathname;

        // Set global path var.
        Globals.Var( "path", Path.length > 1 ? Path.substr(1) : Path );

        if ( location.action === "POP" ) {

            return;

        }

        window.scrollTo( 0, 0 );

    }

    /**
     * Add navigation listener on Router mount.
     * 
     * @return void
     */

    OnRouter = ( router ) => {

        if ( !router ) {

            return;

        }

        const Path = router.history.location.pathname;

        // Set global path var.
        Globals.Var( "path", Path.length > 1 ? Path.substr(1) : Path );

        this.Router = router;

        router.history.listen( this.OnNavigation );

    }

    render() {

        if ( !Auth.LoggedIn() ) {

            return <ViewLogin />;

        }

        return (

            <Router
        
                ref={ this.OnRouter }
                
            >

                <div className="Navigation">

                    <Switch>

                        <Route component={ ViewNotFound } />

                    </Switch>

                </div>

            </Router>

        );
        
    }

}

export default ViewNavigation;