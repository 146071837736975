/**!
 *  Trending content widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./trending.scss";
import API from "Class/API";
import Fuse from "Class/Fuse";
import {StripTags} from "Functions";
import Error from "Components/Feedback/Error";
import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";
import Preview from "Components/Layout/Preview";
import Spinner from "Components/Feedback/Spinner";
import WidgetWrapper from "Components/UI/WidgetWrapper";

class WidgetTrending extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                default: "Trending Content",
                insert: true,
                label: "Title",
                reset: true,
                type: "text"
            },
            community:
            {
                label: "Community",
                placeholder: "Search for community...",
                type: "content",
                types: ["community"]
            },
            communityNotice:
            {
                displayIf: ["community", "==", 0],
                label: "If no community is specified, the trending content for the current community will be loaded.",
                type: "notice"
            }
        }, {
            backgroundColor: "transparent",
            textColor: "black"
        }, true);
        this.Mounted = false;
        this.Name = "Trending Content";
        this.state =
        {
            content: {},
            done: false,
            error: false,
            loading: false,
            trending: []
        };
    }

    /**
     * Load trending content on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        this.Reload();
    }

    /**
     * Reload trending content if the community is changed.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content: ct1} = this.props;
        const {content: ct2} = prevProps;
        const {community: cm1} = ct1 || {};
        const {community: cm2} = ct2 || {};
        const C1 = (cm1 && cm1.length) ? cm1[0][0] : 0;
        const C2 = (cm2 && cm2.length) ? cm2[0][0] : 0;
        if (C1 !== C2)
        {
            this.Load(C1);
        }
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Get/load content.
     * @param integer id - The content id.
     * @return void
     */

    GetContent = (id) =>
    {
        const {content} = this.state;
        if (!content[id])
        {
            content[id] = 1;
            Fuse.Content(id, "", item =>
            {
                const {content} = this.state;
                content[id] = item ? item : -1;
                this.setState({content});
            });
        }
        return content[id];
    }

    /**
     * Output a trending content item.
     * @param integer id - Content id.
     * @param integer index - Item index.
     * @return JSX - The item.
     */

    Item = (id, index) =>
    {
        const CA = ["WidgetTrendingItem"];
        const Item = this.GetContent(id);
        let Content = "";
        let Key = index;
        if (typeof Item === "object")
        {
            const {description, name, preview, type} = Item;
            CA.push("Loaded");
            Key += "loaded";
            Content = (
                <Link
                    className="WidgetTrendingItemLink"
                    href={Fuse.Url(`contents/${id}`, false)}
                    target="_top"
                    title={name}
                >
                    <Preview
                        className="ItemPreview WidgetTrendingItemPreview"
                        content={type}
                        image={preview}
                    />
                    <div className="ItemContent WidgetTrendingItemContent">
                        <div className="ItemTitle WidgetUserActivitiesItemName">{name}</div>
                        <div className="ItemInfo WidgetUserActivitiesItemDescription">{StripTags(description)}</div>
                    </div>
                </Link>
            );
        }
        else if (Item > 0)
        {
            CA.push("Loading");
            Content = (
                <span>
                    <Spinner className="ItemPreview ItemBackground WidgetTrendingItemSpinner"/>
                    <div className="ItemContent WidgetTrendingItemContent">
                        <div className="ItemTitle WidgetUserActivitiesItemName">Loading...</div>
                    </div>
                </span>
            );
        }
        else
        {
            CA.push("ContentError");
            Content = (
                <span>
                    <Icon className="ItemPreview WidgetTrendingItemIcon" feather="X"/>
                    <div className="ItemContent WidgetTrendingItemContent">
                        <div className="ItemTitle WidgetUserActivitiesItemName">Unable to load content</div>
                    </div>
                </span>
            );
        }
        return <div className={CA.join(" ")} key={Key}>{Content}</div>;
    }

    /**
     * Load the leaderboard.
     * @param integer community - Community id.
     * @return void
     */

    Load = (community = 0) =>
    {
        const {context, contextId} = this.props;
        const Current = context === "community" ? contextId : 0;
        const Community = community || Current;
        this.setState({
            error: false,
            leaderboard: {},
            loading: true
        });
        API.Request("engagements/trending", {community: Community}, response =>
        {
            const {error, trending} = response;
            if (!trending || error)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                this.setState({trending, loading: false});
            }
        });
    }

    /**
     * Reload trending content.
     * @param integer community - Community id.
     * @return void
     */

    Reload = () =>
    {
        const {content} = this.props;
        const {community: c} = content || {};
        const Community = (c && c.length) ? c[0][0] : 0;
        this.Load(Community);
    }

    render()
    {
        const WidgetContent = this.Content();
        const {active, hover} = this.props;
        const {error, loading, trending} = this.state;
        const {title} = WidgetContent;
        const CA = this.ClassNames(["WidgetTrending"]);
        const Content = [];
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover"); 
        }
        if (loading)
        {
            CA.push("Loading");
            Content.push(<Spinner
                className="WidgetSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (error)
        {
            CA.push("Error");
            Content.push(<Error
                button="Try again"
                className="WidgetError"
                key="error"
                label="Unable to load the trending content"
                onClick={this.Reload}
            />);
        }
        else
        {
            const Items = [];
            trending.forEach((contentId, index) =>
            {
                Items.push(this.Item(contentId, index));
            });
            Content.push(<div
                className="WidgetTrendingItems"
                key="items"
            >
                {Items}
            </div>);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                <WidgetWrapper
                    {...WidgetContent}
                    className="WidgetTrendingWrapper"
                    title={title}
                >
                    {Content}
                </WidgetWrapper>
            </div>
        );
    }
}

export default WidgetTrending;