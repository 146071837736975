/*!
 *  Fuse comment item
 *
 *  @prop string className - Append a class name.
 *  @prop object comment - Comment object.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./commentitem.scss";
import Fuse from "Class/Fuse";
import Globals from "Class/Globals";
import Parser from "Class/Parser";
import {NoOrphans} from "Functions";
import Button from "Components/UI/Button";
import Collapsable from "Components/Layout/Collapsable";
import Counter from "Components/Feedback/Counter";
import IconButton from "Components/UI/IconButton";
import Link from "Components/UI/Link";
import TextareaField from "Components/UI/Field/TextareaField";
import User from "Components/UI/User";

class CommentItem extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Response = false;
        this.Mounted = false;
        this.state =
        {
            children: [],
            deleted: false,
            error: false,
            expand: false,
            full: false,
            liked: false,
            likes: 0,
            loading: false,
            posting: false,
            response: ""
        };
    }

    /**
     * Load full comment on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        this.Load();
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
    }

    /**
     * Output comment children.
     * @return void
     */

    Children = () =>
    {
        const {children, full} = this.state;
        // Wait until this comment is fully loaded so that the children
        // won't have to be loaded from the API.
        if (!full || !children || !children.length)
        {
            return "";
        }
        const Children = [];
        children.forEach(child =>
        {
            Children.unshift(<CommentItem
                className="CommentItemChild"
                child={true}
                comment={child}
                key={child.id}
            />);
        });
        return <div className="CommentItemChildren">{Children}</div>;
    }

    /**
     * Callback when the delete button is clicked. Confirm action.
     * @return void
     */

    Delete = () =>
    {
        const {comment} = this.props;
        const {can_delete, deleted} = comment || {};
        if (!can_delete || deleted)
        {
            return;
        }
        Globals.DialogCreate({
            confirmLabel: "Delete",
            message: "Do you want to delete this comment?",
            onConfirm: this.OnDelete,
            title: "Delete comment",
            type: "confirm"
        });
    }

    /**
     * Callback when the comment icon is clicked. Expands form + children.
     * @return void
     */

    Expand = () =>
    {
        const {expand} = this.state;
        const Expand = !expand;
        this.setState({expand: Expand});
        setTimeout(() =>
        {
            if (!this.Response)
            {
                return;
            }
            this.Response.Focus();
        }, 300);
    }

    /**
     * Output the respond form.
     * @return void
     */

    Form = () =>
    {
        const {posting, response} = this.state;
        return (
            <div className="CommentItemForm">
                <TextareaField
                    disabled={posting}
                    placeholder="Your response.."
                    ref={node => this.Response = node}
                    onChange={this.OnResponse}
                    onInput={this.OnResponse}
                    value={response}
                />
                <Button
                    disabled={!response}
                    label="Post"
                    loading={posting}
                    onClick={this.Post}
                />
            </div>
        );
    }

    /**
     * Callback when the like button is clicked. Like/unlike depending on state.
     * @return void
     */

    Like = () =>
    {
        const {comment} = this.props;
        const {liked, likes} = this.state;
        const {content_id, id} = comment;
        const Like = !liked;
        this.setState({liked: Like, likes: Like ? likes + 1 : likes - 1});
        if (Like)
        {
            Fuse.Request(`contents/${content_id}/comments/${id}/like`, {}, null, "POST");
        }
        else
        {
            Fuse.Request(`contents/${content_id}/comments/${id}/like`, {}, null, "DELETE");
        }
    }

    /**
     * Load the full comment from the API. This is necessary because we don't
     * receive the full comment object from the buzzing endpoints.
     * @return void
     */

    Load = () =>
    {
        const {comment} = this.props;
        const {children, content_id, deleted, id} = comment;
        this.setState({
            children,
            deleted,
            error: false,
            loading: true
        });
        Fuse.Comment(content_id, id, comment =>
        {
            if (comment)
            {
                const {liked_by_me, likes_count} = comment;
                this.setState({
                    full: true,
                    liked: liked_by_me,
                    likes: likes_count,
                    loading: false,
                });
            }
            else
            {
                this.setState( {error: true, loading: false});
            }
        });
    }

    /**
     * Callback when deletion has been confirmed by the user.
     * @return void
     */

    OnDelete = () =>
    {
        const {comment} = this.props;
        const {content_id, id} = comment;
        this.setState({deleted: true});
        Fuse.Request(`contents/${content_id}/comments/${id}`, {}, null, "DELETE");
    }

    /**
     * Callback when the form textarea is changed.
     * @param object e - Event object.
     * @param string response - Updated field value.
     * @return void
     */

    OnResponse = (e, response) =>
    {
        this.setState({response});
    }

    /**
     * Post a response to the comment.
     * @return void
     */

    Post = () =>
    {
        const {comment} = this.props;
        const {response} = this.state;
        const {content_id, id} = comment;
        if (!response)
        {
            return;
        }
        this.setState({
            error: false,
            posting: true
        });
        Fuse.Request(`contents/${content_id}/comments`,
        {
            comment: response,
            parent_id: id
        }, response =>
        {
            const {comment: c} = response || {};
            if (!c)
            {
                this.setState(
                {
                    error: true,
                    posting: false
                });
            }
            else
            {
                this.Refresh(refreshed =>
                {
                    const {children} = refreshed || {};
                    if (!children)
                    {
                        this.setState({
                            error: true,
                            posting: false
                        });
                    }
                    else
                    {
                        this.setState({
                            children,
                            posting: false,
                            response: ""
                        });
                    }
                });
            }
        }, "POST");
    }

    /**
     * Reload the comment from the Fuse API.
     * @param function callback - Callback when the request is finished.
     * @return void
     */

    Refresh = (callback) =>
    {
        const {comment} = this.props;
        const {content_id, id} = comment;
        Fuse.Comment(content_id, id, callback, true);
    }

    /**
     * Output the comment toolbar.
     * @return void
     */

    Toolbar = () =>
    {
        const {comment} = this.props;
        const {children, deleted, expand, full, liked, likes, loading} = this.state;
        const {can_delete} = comment || {};
        const Buttons = [
            <IconButton
                active={liked}
                className="CommentItemToolbarItem"
                disabled={!full}
                feather="Heart"
                key="like"
                loading={loading}
                onClick={this.Like}
                title="Like this comment"
            />,
            <Counter
                className={liked ? "CommentItemToolbarCounter ItemActive" : "CommentItemToolbarCounter"}
                count={likes}
                key="likes"
            />,
            <IconButton
                active={expand}
                className="CommentItemToolbarItem"
                feather="MessageCircle"
                key="respond"
                loading={loading}
                onClick={this.Expand}
                title="Respont to this comment"
            />,
            <Counter
                className={expand ? "CommentItemToolbarCounter ItemActive" : "CommentItemToolbarCounter"}
                count={children.length}
                key="comments"
            />
        ];
        if (can_delete)
        {
            Buttons.push(<IconButton
                className="CommentItemToolbarItem"
                disabled={deleted}
                feather="Trash"
                key="delete"
                onClick={this.Delete}
                title="Delete this comment"
            />);
        }
        return (
            <div className="CommentItemToolbar">
                {Buttons}
            </div>
        );
    }

    render()
    {
        const {className, child, comment} = this.props;
        const {deleted, expand} = this.state;
        const {body, body_html, content_id, created_at, note, user} = comment || {};
        const CA = ["CommentItem"];
        if (child)
        {
            CA.push("Child");
        }
        if (deleted)
        {
            CA.push("Deleted");
        }
        if (className)
        {
            CA.push(className);
        }
        const Body = String(body_html || body).replace(/\*U\+.{4}\*/g, "");
        return (
            <div className={CA.join(" ")}>
                <User
                    className="ItemPreview CommentItemAvatar"
                    user={user}
                    size={child ? 30 : 60}
                />
                <div className="Item ItemContent CommentItemContent">
                    <div className="CommentItemTitle">
                        <Link
                            className="CommentItemTitleLink"
                            href={Fuse.Url(`contents/${content_id}`, false)}
                            target="_top"
                        >{NoOrphans(note)}</Link>
                        <div className="CommentItemTitleCreated">{created_at} ago</div>
                    </div>
                    {Parser.Parse(Body, "div", {},
                    {
                        className: "CommentItemBody"
                    })}
                    {this.Toolbar()}
                </div>
                <Collapsable
                    className="CommentItemSub"
                    collapsed={!expand}
                >
                    {this.Form()}
                    {this.Children()}
                </Collapsable>  
            </div>
        );
    }
}

CommentItem.propTypes =
{
    className: PropTypes.string,
    child: PropTypes.bool,
    comment: PropTypes.object
};

CommentItem.defaultProps =
{
    className: "",
    child: false,
    comment: {}
};

export default CommentItem;