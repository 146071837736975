/**!
 *  Widget template.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./widget.scss";

import Broadcast from "Class/Broadcast";
import Fuse from "Class/Fuse";
import Globals from "Class/Globals";
import {ArrayClone} from "Functions";
import IconButton from "Components/UI/IconButton";
import LoadImage from "Components/Layout/LoadImage";

class Widget extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Fields = {};
        this.Name = "Widget";
        this.RefWidget = false;
    }

    /**
     * Output the widget background image.
     * @param string src - Alternative background src.
     * @return JSX - The background image.
     */

    BackgroundImage = (src) =>
    {
        const {content} = this.props;
        const {backgroundImage} = content;
        const BackgroundImage = src || (backgroundImage && backgroundImage.length) ? parseInt(backgroundImage[0], 10) : false;
        return BackgroundImage ? <LoadImage
            className="WidgetBackground"
            size="large"
            srcId={BackgroundImage}
        /> : "";
    }

    /**
     * Assign class names to the widget container.
     * @param array input - Input class names.
     * @param boolean stringOutput - Join the class names into a string.
     * @param boolean hasBackground - Alternative background flag.
     * @return array|string - Output class names.
     */

    ClassNames = (input, stringOutput, hasBackground) =>
    {
        const {active, content, hover} = this.props;
        const {background, backgroundImage, backgroundOverlay, grayscale} = content;
        const CA = ArrayClone(input || []);
        const BackgroundImage = hasBackground || ( backgroundImage && backgroundImage.length ) ? parseInt( backgroundImage[0], 10 ) : false;
        CA.unshift("Widget");
        if (active)
        {
            CA.push( "Active" );
        }
        if (BackgroundImage)
        {
            CA.push( "HasBackground" );
        }
        if (backgroundOverlay)
        {
            CA.push("HasBackgroundOverlay");
        }
        if (grayscale)
        {
            CA.push( "Grayscale" );
        }
        if (hover)
        {
            CA.push( "Hover" );
        }
        return stringOutput ? CA.join(" ") : CA;
    }

    /**
     * Parse widget contents. Injects variables into text fields.
     * @param object input - Input content object. Used during recursion.
     * @param object fields - Fields object. Used during recusion.
     * @return array|string - Output class names.
     */

    Content = (input, fields) =>
    {
        const {content} = this.props;
        const Content = {};
        const Input = input || content;
        const Fields = fields || this.Fields;
        const Vars = Fuse.InjectVars();
        // I don't have time to find out why i need to do this.
        const Iterate = Object.keys(Input).length ? Input : Fields;
        //for (let key in Fields)
        //for (let key in Input)
        for (let key in Iterate)
        {
            let {default: defaultValue, fields: subFields, insert, type} = Fields[key] || {};
            if (Input[key] === undefined)
            {
                Content[key] = defaultValue || "";
            }
            else if (type === "repeater")
            {
                Content[key] = [];
                Input[key].forEach((item, index) =>
                {
                    Content[key][index] = this.Content(item, subFields);
                });
            }
            else if (!insert || typeof Input[key] !== "string")
            {
                Content[key] = Input[key];
            }
            else
            {
                Content[key] = Input[key].replace(/@\{([a-z]*)\}/gi, (f, key) =>
                {
                    return Vars[key] ? Vars[key][1] : "";
                });
            }
        }
        return Content;
    }

    /**
     * Callback when the edit container button is clicked.
     * @return void
     */

    OnEditContainer = () =>
    {
        const {id} = this.props;
        Broadcast.SendMessage({
            type: "toolbar",
            action: "1",
            id
        });
    }

    /**
     * Callback when the edit content button is clicked.
     * @return void
     */

    OnEditContent = () =>
    {
        const {id} = this.props;
        Broadcast.SendMessage({
            type: "toolbar",
            action: "2",
            id
        });
    }

    /**
     * Callback when the widget requests a new height. If dynamic height is
     * enabled -- this request will be propagated to its' parent grid.
     * @param object e - The event object.
     * @param integer height - The requested height.
     * @return void
     */

    OnHeight = (e, height) =>
    {
        const {onHeight} = this.props;
        onHeight(e, height);
    }

    /**
     * Callback when the cursor leaves the widget toolbar.
     * Toggles the 'Hover' class on the widget node with vanilla JS for speed.
     * @return void
     */

    OnToolbarOut = () =>
    {
        if (!this.RefWidget)
        {
            return;
        }
        this.RefWidget.classList.remove("Hover");
    }

    /**
     * Callback when the cursor enters the widget toolbar.
     * Toggles the 'Hover' class on the widget node with vanilla JS for speed.
     * @return void
     */

    OnToolbarOver = () =>
    {
        if (!this.RefWidget)
        {
            return;
        }
        this.RefWidget.classList.add("Hover");
    }

    /**
     * Append default fields to a widget.
     * @param object input - Optional input fields.
     * @return object - The parsed field object.
     */

    SetFields = (input, defaults = {}, resetColors = false) =>
    {
        return Object.assign(input || {},
        {
            backgroundImage:
            {
                label: "Background Image",
                type: "image",
                reset: resetColors
            },
            backgroundOverlay:
            {
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Background Overlay",
                type: "checkbox",
                default: defaults.backgroundOverlay !== undefined ?  defaults.backgroundOverlay : true,
                reset: resetColors
            },
            backgroundColor:
            {
                displayIf: ["backgroundImage", "===", "empty"],
                gradient: true,
                label: "Background Color",
                type: "color",
                default: defaults.backgroundColor || "gradient",
                reset: resetColors
            },
            textColor:
            {
                //displayIf: ["backgroundImage", "===", "empty"],
                label: "Text Color",
                type: "color",
                default: defaults.textColor || "white",
                reset: resetColors
            },
            grayscale:
            {
                displayIf: ["backgroundImage", "!==", "empty"],
                label: "Grayscale",
                type: "checkbox",
                default: defaults.grayscale !== undefined ?  defaults.grayscale : true,
                reset: resetColors
            }
        });
    };

    Style = () =>
    {
        const {background, backgroundColor, backgroundImage, textColor} = this.Content();
        const {orange, pink, purple, white} = Globals.Setting("Colors", {});
        const Style = {};
        if ((background || backgroundColor) && (!backgroundImage || !backgroundImage.length))
        {
            if (Array.isArray(backgroundColor))
            {
                if (backgroundColor.length === 3)
                {
                    const [color1, color2, rotation] = backgroundColor;
                    Style.background = `linear-gradient(${rotation}deg, ${color1}, ${color2})`;
                }
                else
                {
                    Style.backgroundColor = backgroundColor[0];
                }
            }
            else
            {
                switch (backgroundColor)
                {
                    case "gradient":
                        Style.background = `linear-gradient(135deg, ${orange}, ${pink})`;
                        break;
                    case "orange":
                        Style.backgroundColor = orange;
                        break;
                    case "pink":
                        Style.backgroundColor = pink;
                        break;
                    case "purple":
                        Style.backgroundColor = purple;
                        break;
                    case "white":
                        Style.backgroundColor = white;
                        break;
                    default:
                        Style.backgroundColor = backgroundColor;
                }
            }
        }
        Style.color = textColor || "#ffffff";
        return Style;
    }

    /**
     * Render the widget toolbar for admins/managers.
     * @return JSX - The toolbar.
     */

    Toolbar = (invert) =>
    {
        const {editContainer, editContent, toolbarOffset} = this.props;
        if (!editContainer && !editContent)
        {
            return "";
        }
        const Offset = toolbarOffset * 115;
        const Style = {transform: `translateY(${Offset}%)`};
        const CA = [ "WidgetToolbarWrapper" ];
        if (invert)
        {
            CA.push( "Invert" );
        }
        return (
            <div className={CA.join(" ")} style={Style}>
                <div
                    className="WidgetToolbar"
                    onMouseEnter={this.OnToolbarOver}
                    onMouseLeave={this.OnToolbarOut}  
                >
                    <IconButton
                        className="WidgetToolbarContainer"
                        disabled={!editContainer}
                        feather="Square"
                        onClick={this.OnEditContainer}
                        title="Edit this widget container"
                    />
                    <IconButton
                        className="WidgetToolbarContent"
                        disabled={!editContent}
                        feather="Edit"
                        onClick={this.OnEditContent}
                        title="Edit this widget content"
                    />
                </div>
            </div>
        );
    }

    render()
    {
        return "";
    }
}

Widget.propTypes =
{
    active: PropTypes.bool,
    ancestors: PropTypes.array,
    appearance: PropTypes.object,
    attributes: PropTypes.object,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    contentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    context: PropTypes.string,
    contextId: PropTypes.string,
    editContainer: PropTypes.bool,
    editContent: PropTypes.bool,
    hover: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    onHeight: PropTypes.func,
    toolbarOffset: PropTypes.number
};

Widget.defaultProps =
{
    active: false,
    ancestors: [],
    appearance: {},
    attributes: {},
    content: false,
    contentId: "",
    context: "",
    contextId: "",
    editContainer: false,
    editContent: false,
    hover: false,
    id: "",
    name: "",
    onHeight: () => {},
    toolbarOffset: 0
};

export default Widget;