
/**!
 *  Overlay dialogs handler.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./dialog.scss";

import Globals from "Class/Globals";

import Button from "Components/UI/Button";
import DraftOverview from "Components/UI/DraftsOverview";
import FileUpload from "Components/UI/FileUpload";
import Form from "Components/UI/Form";
import ImageDetails from "Components/UI/ImageDetails";
import ImageGallery from "Components/UI/ImageGallery";
import Overlay from "Components/Layout/Overlay";
import WidgetGallery from "Components/UI/WidgetGallery";

class ViewDialog extends React.Component {

    constructor( props ) {

        super( props );

        this.Mounted = false;

        this.state = {

            dialogs: {},

        };

    }

    /**
     * Listen for dialog requests.
     * 
     * @return void
     */

    componentDidMount() {

        this.Mounted = true;

        this.setState( { dialogs: Globals.Dialogs() } );

        Globals.Listen( "dialog-create", this.OnDialog );
        Globals.Listen( "dialog-destroy", this.OnDialog );

    }

    /**
     * Register unmount.
     * 
     * @return void
     */

    componentWillUnmount() {

        this.Mounted = false;

        Globals.Remove( "dialog", this.OnDialog );
        Globals.Remove( "dialog-destroy", this.OnDialog );

    }

    /**
     * Create a overlay dialog.
     * 
     * @param object dialog - Dialog object.
     * @param string id - The unique ID of the dialog.
     * 
     * @return JSX - The overlay dialog.
     */

    DialogItem = ( dialog, id ) => {

        const {
            
            cancelLabel,
            confirmLabel,
            message,
            props,
            title,
            type
            
        } = dialog;

        let Content = "";
        let DefaultTitle = "";
        let OverlayClose = true;
        let Width;

        switch ( type ) {

            // Confirm action.
            case "confirm":

                Content = (

                    <div className="DialogConfirm">

                        <div className="DialogMessage">{ message }</div>
                    
                        <div className="DialogButtons">

                            <Button
                            
                                label={ confirmLabel || "OK" }
                                onClick={ () => this.OnConfirm( id ) }
                            
                            />

                            <Button
                            
                                hollow={ true }
                                label={ cancelLabel || "Cancel" }
                                onClick={ () => this.OnClose( id ) }
                            
                            />

                        </div>

                    </div>

                );

                DefaultTitle = "Confirm action";

                break;

            // Widget container drafts overview.
            case "drafts":

                Content = <DraftOverview

                    { ...props }

                    onClose={ () => this.OnClose( id ) }
                
                />;

                DefaultTitle = "Draft versions overview";
                Width = 480;

                break;

            // Image details interface.
            case "image":

                Content = <ImageDetails
                
                    { ...props }

                    onClose={ () => this.OnClose( id ) }
                
                />;

                DefaultTitle = "Image details";
                OverlayClose = false;

                break;

            // Form fields.
            case "form":

                Content = (
                
                    <div className="DialogForm">
                
                        <Form
                        
                            { ...props }
                            
                        />

                        <Button
                        
                            className="DialogFormDone"
                            label="Done"
                            onClick={ () => this.OnClose( id ) }
                            
                        />

                    </div>

                );

                DefaultTitle = "Edit";
                OverlayClose = false;
                Width = 480;

                break;

            // Image gallery.
            case "gallery":

                Content = <ImageGallery
                
                    { ...props }

                    onClose={ () => this.OnClose( id ) }
                    
                />

                DefaultTitle = "Pick file";
                OverlayClose = false;
                Width = 480;

                break;

            // Upload file.
            case "upload":

                Content = <FileUpload
                
                    { ...props }

                    onClose={ () => this.OnClose( id ) }
                    
                />;

                DefaultTitle = "Upload file";

                break;

            // Widget content gallery.
            case "widgets":

                Content = <WidgetGallery
                
                    { ...props }

                    onClose={ () => this.OnClose( id ) }
                    
                />

                DefaultTitle = "Load widget content";
                Width = 480;

                break;

            default:

        }

        return (

            <Overlay
            
                className="Dialog"
                key={ id }
                title={ title || DefaultTitle }
                onClose={ () => this.OnClose( id ) }
                overlayClose={ OverlayClose }
                width={ Width }
                
            >
        
                { Content }
            
            </Overlay>

        );
        
    }

    /**
     * Callback when a dialog is confirmed.
     * 
     * @return void
     */

    OnConfirm = ( id ) => {

        const { dialogs } = this.state;
        const Dialog = dialogs[ id ];

        if ( !Dialog ) {

            return;

        }

        if ( typeof Dialog.onConfirm === "function" ) {

            Dialog.onConfirm();

        }

        Globals.DialogDestroy( id, true );

    }

    /**
     * Callback when the dialog is closed.
     * 
     * @return void
     */

    OnClose = ( id ) => {

        Globals.DialogDestroy( id );

    }

    /**
     * Callback when a dialog id created/destroyed.
     * 
     * @return void
     */

    OnDialog = () => {

        if ( !this.Mounted ) {

            return;

        }

        this.setState( { dialogs: Globals.Dialogs() } );

    }

    render() {

        const { dialogs } = this.state;
        const Dialogs = [];

        for ( let id in dialogs ) {

            Dialogs.push( this.DialogItem( dialogs[ id ], id ) );

        }
        
        return (

            <div className="Dialogs">

                { Dialogs }

            </div>

        );

    }

}

export default ViewDialog;