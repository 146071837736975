/**!
 *  Banner widget with links.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./bannerlinks.scss";
import Parser from "Class/Parser";
import {RandomToken} from "Functions";
import ContentItem from "Components/UI/ContentItem";
import Slider from "Components/UI/Slider";

class WidgetBannerLinks extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            { 
                insert: true,
                label: "Title",
                type: "text"
            },
            text:
            {
                insert: true,
                label: "Text",
                type: "textarea"
            },
            links:
            {
                addLabel: "Add link",
                onLabel: ["content", "content", 0],
                label: "Links",
                type: "repeater",
                nameKey: "label",
                fields:
                {
                    externalLink:
                    {
                        label: "External link",
                        type: "checkbox"
                    },
                    content:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Content",
                        type: "content",
                        types: ["content::article", "community", "externalLink", "event", "question", "scormcourse", "uploadedfile", "video"]
                    },
                    contentNotice:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Label and Image will replace the contents preview and name if specified.",
                        type: "notice"
                    },
                    url:
                    {
                        displayIf: ["externalLink", "==", true],
                        label: "URL",
                        type: "url",
                        default: "https://"
                    },
                    urlBlank:
                    {
                        displayIf: ["externalLink", "==", true],
                        label: "Open in a new tab",
                        type: "checkbox",
                        default: true
                    },
                    urlBlank2:
                    {
                        displayIf: ["externalLink", "!=", true],
                        label: "Open in a new tab",
                        type: "checkbox",
                        default: false
                    },
                    label:
                    {  
                        label: "Label" 
                    },
                    image:
                    {
                        label: "Image",
                        type: "image"
                    },
                    grayscale:
                    {
                        label: "Grayscale",
                        type: "checkbox",
                        default: true
                    }
                }
            },
            linksTint:
            {
                label: "Tint Link Previews",
                type: "checkbox",
                default: false
            }
        });
        this.Break = 900;
        this.LastHeight = 0;
        this.Name = "Banner with links";
        this.RefContent = false;
        this.RefWidget = false;
        this.ResizeTimer = false;
        this.state =
        {
            narrow: false
        };
    }

    /**
     * Adjust layout and add listeners on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Adjust();
        window.addEventListener("resize", this.OnResize);
    }

    /**
     * Adjust height on update.
     * @return void
     */

    componentDidUpdate()
    {
        this.AdjustHeight();
    }

    /**
     * Remove listeners on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.OnResize);
    }

    /**
     * Adjust the layout to fit the container.
     * @return void
     */

    Adjust = () =>
    {
        if (!this.RefWidget)
        {
            return;
        }
        const {narrow} = this.state;
        const Narrow = this.RefWidget.offsetWidth < this.Break;
        if (narrow !== Narrow)
        {
            this.setState({narrow: Narrow}, () =>
            {
                if (Narrow)
                {
                    this.AdjustHeight();
                }
            });
        }
        else if (narrow)
        {
            this.AdjustHeight();
        }
    }

    /**
     * Adjust the widget height when in narrow mode.
     * @return void
     */

    AdjustHeight = () =>
    {
        if (!this.RefContent)
        {
            return;
        }
        const Height = this.RefContent.offsetHeight;
        if (Height === this.LastHeight || Height < 20)
        {
            return;
        }
        this.LastHeight = Height;
        this.OnHeight(null, Height);
    }

    /**
     * Refresh the grid when the client resizes.
     * @return void
     */

    OnResize = () =>
    {
        const {autoAdjust} = this.props;
        if (!autoAdjust)
        {
            return;
        }
        // Set/reset a timeout to avoid hammering.
        clearTimeout(this.ResizeTimer);
        this.ResizeTimer = setTimeout(() =>
        {
            this.Adjust();
        }, 100);
    }

    render()
    {
        const {rowHeight} = this.props;
        const {narrow} = this.state;
        const {links, linksTint, text, title} = this.Content();
        const CA = ["WidgetBannerLinks"];
        const RowHeight = Math.min(1, rowHeight);
        if (narrow)
        {
            CA.push("Narrow");
        }
        const Items = [];
        (links || []).forEach(link =>
        {
            // Assign a unique id/key to each link to avoid re-render.            
            if (!link.id)
            {
                link.id = RandomToken();
            }
            Items.push(
                <div className="WidgetBannerLinksItem" key={link.id}>
                    <ContentItem content={link} overlay={linksTint} sizeMultiplier={RowHeight}/>
                </div>
            );
        });
        return (
            <div className={this.ClassNames(CA, true)} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                <div className="WidgetBannerLinksContent" ref={content => this.RefContent = content} style={{height: 240 * RowHeight, margin: (-120 * RowHeight) + "px auto 0"}}>
                    <div className="WidgetBannerLinksContentText">
                        <h2>{title}</h2>
                        {Parser.ParseParagraphs(text)}
                    </div>
                    <Slider className="WidgetBannerLinksItems" style={{height: 210 * RowHeight}}>
                        {Items}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default WidgetBannerLinks;