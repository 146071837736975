
/*!
 *  Image gallery item
 *
 *  @prop boolean active - Whether the item should be displayed as active.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the item should be disabled.
 *  @prop string|number id - Item id used in callbacks.
 *  @prop object image - Image object.
 *  @prop function onClick - Callback when the item is clicked.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./imageitem.scss";

import Icon from "Components/Layout/Icon";
import LoadImage from "Components/Layout/LoadImage";
import Progress from "Components/Feedback/Progress";

class ImageItem extends React.Component {

    /**
     *  Callback when the item is clicked.
     * 
     *  @param object e - The event object.
     *  
     *  @return void.
     */

    OnClick = (e) => {

        const { disabled, id, image, onClick } = this.props;
        const { urls } = image || {};

        if ( disabled || !urls ) {

            return;

        }

        onClick( e, image, id );

    }

    render() {

        const { active, className, disabled, id, image } = this.props;
        const { error, filename, deleted, preview, progress, token, urls } = image || {};
        const { thumbnail } = urls || {};
        const CA = [ "ImageItem" ];
        const Thumbnail = preview || thumbnail;
        const Content = [];

        if ( className ) {

            CA.push( className );

        }

        if ( disabled || deleted || error ) {

            CA.push( "Disabled" );

        }

        else if ( active ) {

            CA.push( "Active" );

        }

        if ( error ) {

            CA.push( "Error" );

            Content.push( <Icon
            
                className="ImageItemIcon"
                feather="AlertTriangle"
                key="error"
                size={ 36 }
                title="Error when uploading image."
            
            /> );

        }

        else if ( deleted ) {

            CA.push( "Deleted" );

            Content.push( <Icon
            
                className="ImageItemIcon"
                feather="Trash"
                key="deleted"
                size={ 36 }
                title="This image has been deleted."
            
            /> );

        }

        else if ( token && token !== id ) {

            CA.push( "Duplicate" );

            Content.push( <Icon
            
                className="ImageItemIcon"
                feather="Copy"
                key="duplicate"
                size={ 36 }
                title="This image has already been uploaded to the gallery."
            
            /> );

        }

        const CS = CA.join( " " );

        return (

            <div
            
                className={ CS }
                onClick={ this.OnClick }
                title={ filename }
                
            >

                <div className="ImageItemWrapper">

                    <div className="ImageItemContent">

                        { Thumbnail ? <LoadImage
                        
                            className="ImageItemPreview"
                            src={ Thumbnail }
                        
                        /> : "" }

                        { progress !== undefined ? <Progress
                        
                            className="ImageItemProgress"
                            full={ true }
                            percent={ false }
                            progress={ progress }
                        
                        /> : "" }

                        <div className="ImageItemCenter">

                            { Content }
                        
                        </div>

                    </div>

                </div>

            </div>

        );
 
    }

}

ImageItem.propTypes = {

    active: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    image: PropTypes.oneOfType( [ PropTypes.object, PropTypes.bool ] ),
    onClick: PropTypes.func

};

ImageItem.defaultProps = {

    active: false,
    className: "",
    disabled: false,
    id: "",
    image: false,
    onClick: () => {}

};

export default ImageItem;