
/**!
 *  Authentication interface.
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import API from "Class/API";
import Globals from "Class/Globals";

class Auth {

    constructor() {

        this.Key = false;
        this.Token = false;
        this.User = false;
        this.UserId = false;

    }

    CanManage = ( context, id ) => {

        if ( !this.User ) {

            return false;

        }

        const { admin, manages } = this.User;
        const Id = parseInt( id, 10 );

        if ( admin ) {

            return true;

        }

        switch( context ) {

            case "community":

                return manages.indexOf( Id ) >= 0;

            default:

                return false;

        }

    }

    IsAdmin = () => {

        return this.User && this.User.admin;

    }

    IsManager = ( communityId ) => {

        if ( communityId !== undefined ) {

            return this.User && this.User.manages.indexOf( communityId ) >= 0;

        }

        return this.User && this.User.manages.length;

    }

    Ready = () => {

        return this.Token && this.UserId;

    }

    Login = ( username, password, callback ) => {

        if ( typeof callback !== "function" ) {

            callback = () => {};

        }

        API.Request( "auth/login", { username, password }, response => {

            callback( response );

        } );

    }

    LoggedIn = () => {

        return this.Ready();

    }

    Member = ( communityId ) => {

        if ( !this.User ) {

            return false;

        }

        return this.User.communities.indexOf( communityId ) >= 0;

    }

    SetCredentials = ( userId, token, key ) => {

        this.Key = key;
        this.Token = token;
        this.UserId = userId;

        Globals.Trigger( "auth", userId, token, key );

    }

    SetUser = ( user ) => {

        this.User = user;

        Globals.Trigger( "user", user );

    }

}

export default new Auth();