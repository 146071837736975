
/*!
 *  Define a set of contextual conditions. The actual validation of the
 *  conditions should be made in the widget and is not included in this
 *  field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop object value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./conditionsfield.scss";

import { ArrayClone, CanForEach, DefaultValue, ObjectCompare, ParsedValue, RandomToken } from "Functions";

import ContentField from "Components/UI/Field/ContentField";
import DateField from "Components/UI/Field/DateField";
import DateTimeField from "Components/UI/Field/DateTimeField";
import IconButton from "Components/UI/IconButton";
import IconItem from "Components/UI/IconItem";
import SelectField from "Components/UI/Field/SelectField";
import TimeField from "Components/UI/Field/TimeField";

import Conditions from "Import/JSON/conditions.json";

class ConditionsField extends React.Component {

    constructor( props ) {

        super( props );

        this.Options = {};

        for ( let key in Conditions ) {

            this.Options[ key ] = Conditions[ key ].name

        }

        this.state = {

            and: 0,
            value: []

        };

    }

    /**
     * Parse/set conditions on mount.
     * 
     * @return void
     */

    componentDidMount() {

        const { value } = this.props;

        this.SetConditions( value );

    }

    /**
     * Re-parse conditions when a new object is received.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { value: v1 } = nextProps;
        const { value: v2 } = this.state;

        if ( !ObjectCompare( v1, v2 ) ) {

            this.SetConditions( v1 );

        }

    }

    /**
     * Add a new condition to a rule group.
     * 
     * @param integer groupIndex - The rule group index.
     * 
     * @return object|void - The condition if the rule group is unspecified.
     */

    AddCondition = ( groupIndex ) => {

        const { value } = this.state;
        const Condition = {

            id: RandomToken(),
            type: -1,
            operator: -1,
            value: ""

        };

        if ( groupIndex < 0 || value[ groupIndex ] === undefined ) {

            return Condition;

        }

        value[ groupIndex ].children.push( Condition );

        this.setState( { value } );

    }

    /**
     * Add a new rule group.
     * 
     * @return void
     */

    AddGroup = () => {

        const { value } = this.state;

        value.push( {

            children: [ this.AddCondition( -1 ) ],
            id: RandomToken()

        } );
        
        this.setState( { value } );

    }

    /**
     * Render a rule group item.
     * 
     * @param object group - Group data.
     * @param integer groupIndex - The group index.
     * 
     * @return JSX - The rendered group.
     */

    Group = ( group, groupIndex ) => {

        const { disabled } = this.props;
        const { children, id } = group;
        const Items = [];

        children.forEach( ( item, index ) => {

            Items.push( this.Item( groupIndex, item, index ) );

        } );

        return (

            <div

                className="ConditionsFieldGroup"
                key={ id }
            
            >

                <div className="ConditionsFieldGroupContent">

                    <div className="ConditionsFieldGroupItems">

                        { Items }

                    </div>

                    <IconItem
                    
                        disabled={ disabled }
                        feather="Plus"
                        label="And..."
                        onClick={ () => this.AddCondition( groupIndex ) }
                    
                    />

                </div>

            </div>

        );

    }

    /**
     * Render a condition item.
     * 
     * @param integer groupIndex - The group index.
     * @param object item - Condition data.
     * @param integer index - The condition index.
     * 
     * @return JSX - The rendered item.
     */

    Item = ( groupIndex, item, index ) => {

        const { disabled } = this.props;
        const { id, operator, type, value } = item;
        const Condition = Conditions[ type ];
        const { defaultOperator, operators, field } = Condition || {};
        const { type: fieldType } = field || {};
        const Id = `${groupIndex}_${index}`;
        const Value = ParsedValue( value, fieldType, DefaultValue( fieldType ) );
        let Field;

        switch ( fieldType ) {

            case "content":

                Field = <ContentField

                    { ...field }
                
                    id={ Id }
                    label=""
                    onChange={ this.SetValue }
                    value={ Value }

                />;
                break;

            case "date":

                Field = <DateField

                    { ...field }
                
                    id={ Id }
                    label=""
                    onChange={ this.SetValue }
                    value={ Value }

                />;
                break;

            case "dateTime":

                Field = <DateTimeField

                    { ...field }
                
                    id={ Id }
                    label=""
                    onChange={ this.SetValue }
                    value={ Value }

                />;
                break;

            case "select":

                Field = <SelectField

                    { ...field }
                
                    id={ Id }
                    label=""
                    onChange={ this.SetValue }
                    value={ Value }

                />;
                break;

            case "time":

                Field = <TimeField

                    { ...field }
                
                    id={ Id }
                    label=""
                    onChange={ this.SetValue }
                    value={ Value }

                />;
                break;

            default:

                Field = "";

        }

        return (

            <div

                className="ConditionsFieldItem"
                key={ id }
            
            >

                <div className="ConditionsFieldItemType">

                    <SelectField
                    
                        id={ Id }
                        disabled={ disabled }
                        onChange={ this.SetType }
                        options={ this.Options }
                        placeholder="Select condition..."
                        value={ type }
                    
                    />

                </div>

                <div className="ConditionsFieldItemOperator">

                    { operators ? <SelectField
                    
                        id={ Id }
                        disabled={ disabled }
                        onChange={ this.SetOperator }
                        options={ operators }
                        value={ ( operator < 0 ? defaultOperator : operator ) || -1 }
                    
                    /> : "" }

                </div>

                <div className="ConditionsFieldItemField">

                    { Field }

                </div>

                <IconButton
                
                    id={ Id }
                    className="ConditionsFieldItemRemove"
                    disabled={ disabled }
                    feather="X"
                    onClick={ this.RemoveItem }
                    title="Remove this condition"
                
                />

            </div>

        );

    }

    /**
     * Remove a condition from a rule group.
     * 
     * @param object e - The event object.
     * @param string indexes - Contains the group and item index.
     * 
     * @return void
     */

    RemoveItem = ( e, indexes ) => {

        const { id, onChange } = this.props;
        const { value } = this.state;
        const [ GroupIndex, Index ] = indexes.split( "_" );

        if ( value[ GroupIndex ] === undefined || value[ GroupIndex ].children[ Index ] === undefined ) {

            return;

        }

        value[ GroupIndex ].children.splice( Index, 1 );

        if ( !value[ GroupIndex ].children.length ) {

            value.splice( GroupIndex, 1 );

        }

        this.setState( { value } );

        onChange( e, ArrayClone( value ), id );

    }

    /**
     * Set/update the value of the field
     * 
     * @param array value - Field value.
     * 
     * @return void
     */

    SetConditions = ( value ) => {

        const Value = CanForEach( value ) ? ArrayClone( value ) : [];

        this.setState( { value: Value } );

    }

    /**
     * Change the comparison operator of a condition.
     * 
     * @param object e - The event object.
     * @param integer|string operator - The operator key.
     * @param string indexes - Contains the group and item index.
     * 
     * @return void
     */

    SetOperator = ( e, operator, indexes ) => {

        const { id, onChange } = this.props;
        const { value } = this.state;
        const [ GroupIndex, Index ] = indexes.split( "_" );

        if ( value[ GroupIndex ] === undefined || value[ GroupIndex ].children[ Index ] === undefined ) {

            return;

        }

        value[ GroupIndex ].children[ Index ].operator = operator;

        this.setState( { value } );

        onChange( e, ArrayClone( value ), id );

    }

    /**
     * Change the type of a condition.
     * 
     * @param object e - The event object.
     * @param integer|string type - The type key.
     * @param string indexes - Contains the group and item index.
     * 
     * @return void
     */

    SetType = ( e, type, indexes ) => {

        const { id, onChange } = this.props;
        const { value } = this.state;
        const [ GroupIndex, Index ] = indexes.split( "_" );

        if ( value[ GroupIndex ] === undefined || value[ GroupIndex ].children[ Index ] === undefined ) {

            return;

        }

        value[ GroupIndex ].children[ Index ].operator = -1;
        value[ GroupIndex ].children[ Index ].type = type;
        value[ GroupIndex ].children[ Index ].value = DefaultValue( type );

        this.setState( { value } );

        onChange( e, ArrayClone( value ), id );

    }

    /**
     * Change the comparison value of a condition.
     * 
     * @param object e - The event object.
     * @param mixed value - The new value.
     * @param string indexes - Contains the group and item index.
     * 
     * @return void
     */

    SetValue = ( e, newValue, indexes ) => {

        const { id, onChange } = this.props;
        const { value } = this.state;
        const [ GroupIndex, Index ] = indexes.split( "_" );

        if ( value[ GroupIndex ] === undefined || value[ GroupIndex ].children[ Index ] === undefined ) {

            return;

        }

        value[ GroupIndex ].children[ Index ].value = newValue;

        this.setState( { value } );

        onChange( e, ArrayClone( value ), id );

    }

    /**
     * Get the field value.
     * 
     * @return string - The field value.
     */

    Value = () => {

        return ArrayClone( this.state.value );

    }

    render() {

        const { className, disabled, label } = this.props;
        const { value } = this.state;
        const CA = [ "ConditionsField" ];

        if ( className ) CA.push( className );
        if ( disabled ) CA.push( "Disabled" );

        const CS = CA.join( " " );
        const Groups = [];

        if ( CanForEach( value ) ) {

            value.forEach( ( group, index ) => {

                Groups.push( this.Group( group, index ) );

            } );

        }

        return (

            <div className={ CS }>

                { label ?  <label>

                    { label }

                </label> : "" }

                <div className="ConditionsFieldGroups">

                    { Groups }

                </div>

                <IconItem
                
                    className="ConditionsFieldAdd"
                    disabled={ disabled }
                    feather="Plus"
                    label="Add Rule Group"
                    onClick={ this.AddGroup }
                
                />

            </div>

        );

    }

}

ConditionsField.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.array

};

ConditionsField.defaultProps = {

    className: "",
    disabled: false,
    id: "",
    label: "",
    onChange: () => {},
    value: []

};

export default ConditionsField;