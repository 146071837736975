
/**!
 *  Center contents vertically and horisontally.
 *
 *  @prop string className - Append a class name.
 *  @prop string|integer padding - Content padding.
 *  @prop object props - Container node props.
 *  @prop string|integer width - Content width.
 *  @prop JSX [ ...children ] - Content.
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./center.scss";

class Center extends React.Component {

    render() {

        const { children, className, padding, props, width } = this.props;
        const CA = [ "CenterContentScroll" ];
        
        if ( className ) CA.push( className );

        const CS = CA.join( " " );

        return(

            <div { ...props } className={ CS }>

                <div className="CenterContentContainer">

                    <div className="CenterContentAlign" style={ padding ? { padding } : {} }>

                        <div className="CenterContent" style={ width ? { maxWidth: width } : {} }>

                            { children }

                        </div>

                    </div>

                </div>

            </div>

        );

    }

}

Center.propTypes = {

    className: PropTypes.string,
    padding: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    props: PropTypes.object,
    width: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] )

};

Center.defaultProps = {

    className: "",
    padding: 0,
    props: {},
    width: 340

};

export default Center;