
/**!
 *  Fuse API functionality
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import API from "Class/API";
import Auth from "Class/Auth";
import Globals from "Class/Globals";

class Fuse {

    constructor() {

        this.ApiUrl = "";
        this.Comments = {};
        this.ContentNames = {};
        this.Context = "";
        this.ContextId = 0;
        this.FuseUrl = "";
        this.FuseDefault = Globals.Setting( "FuseDefault" );
        this.FuseOverride = Globals.Setting( "FuseOverride", [] );
        this.OriginAllowed = Globals.Setting( "OriginAllowed", [] );

    }

    /**
     * Attempt to fetch a comment from Fuse. Since there is no endpoint to
     * fetch a single comment, we need to fetch all comments for the content
     * and search for the comment within it.
     * 
     * @param string|integer contentId - The content id.
     * @param string|integer commentId - The comment id.
     * @param function callback - Callback when finished.
     * @param boolean refresh - Force fresh API call.
     * 
     * @return object|boolean - The comment object or 'false' when not found.
     */

    Comment = ( contentId, commentId, callback, refresh ) => {

        if ( typeof callback !== "function" ) {

            callback = () => {};

        }

        if ( !refresh && this.Comments[ commentId ] !== undefined ) {

            callback( this.Comments[ commentId ] );

        }

        else {

            this.Request( `contents/${contentId}/comments`, { per_page: 200 }, response => {

                const { comments } = response;

                // Create a walker function to parse the comments recursively.
                const Walker = level => {

                    level.forEach( c => {

                        const { id, children } = c;

                        this.Comments[ id ] = c;

                        Walker( children );

                    } );

                };

                Walker( comments );

                if ( this.Comments[ commentId ] !== undefined ) {

                    callback( this.Comments[ commentId ] );

                }

                else {

                    callback( false );

                }

            } );

        }

    }

    /**
     * Fetch content from Fuse.
     * 
     * @param string|integer id - Fuse content ID.
     * @param string type - Fuse content type.
     * @param function callback - Callback when the content has been received.
     * 
     * @return void
     */

    Content = ( id, type, callback ) => {

        if ( typeof callback !== "function" ) {

            callback = () => {};

        }

        const Id = parseInt( id, 10 );

        if ( !Id || isNaN( Id ) ) {

            callback( false );

        }

        else {

            switch ( type ) {

                case "community":

                    this.Request( `communities/${Id}`, response => {

                        if ( !response || response.id !== Id ) {

                            callback( false );

                        }

                        else {

                            const {

                                description,
                                name,
                                icon: preview

                            } = response;

                            callback( {

                                accessible: true,
                                description,
                                id: Id,
                                name,
                                preview,
                                raw: response,
                                type

                            } );

                        }

                    } );

                    break;

                case "topic":

                    this.Request( `topics/${Id}`, response => {

                        if ( !response || response.id !== Id ) {

                            callback( false );

                        }

                        else {

                            const {

                                accessible,
                                name,
                                icon_url: preview

                            } = response;

                            callback( {

                                accessible,
                                description: "",
                                id: Id,
                                name,
                                preview,
                                raw: response,
                                type

                            } );

                        }

                    } );

                    break;

                case "user":

                    this.Request( `users/${Id}`, response => {

                        if ( !response || response.id !== Id ) {

                            callback( false );

                        }

                        else {

                            const {

                                name,
                                avatar_url: preview

                            } = response;

                            callback( {

                                accessible: true,
                                description: "",
                                id: Id,
                                name,
                                preview,
                                raw: response,
                                type

                            } );

                        }

                    } );

                    break;

                default:

                    this.Request( `contents/${Id}`, response => {

                        if ( !response || response.id !== Id ) {

                            callback( false );

                        }

                        else {

                            const {

                                accessible,
                                content_type,
                                description,
                                name,
                                preview

                            } = response;

                            callback( {

                                accessible,
                                description,
                                id: Id,
                                name,
                                preview,
                                raw: response,
                                type: type || content_type.toLowerCase()

                            } );

                        }

                    } );

            }

        }

    }

    ContentName = ( item, callback ) => {

        let Id = typeof item === "object" ? item[0] : item;
        let Type = typeof item === "object" ? item[1] : 0;

        if ( this.ContentNames[ Id ] !== undefined ) {

            callback( this.ContentNames[ Id ] );

        }

        else if ( Type ) {

            this.Content( Id, Type, content => {

                if ( !content ) {

                    callback( "" );

                }

                else {

                    callback( this.ContentNames[ Id ] = content.name );

                }

            } );

        }

        else {

            API.Request( "content/cache-read", { id: Id }, response => {

                const { cache, error } = response;

                if ( error ) {

                    callback( "" );

                }

                else {

                    this.Content( Id, cache.type, content => {

                        if ( !content ) {

                            callback( "" );

                        }

                        else {

                            callback( this.ContentNames[ Id ] = content.name );

                        }

                    } );

                }

            } );

        }

    }

    /**
     * Create a Fuse content URL
     * 
     * @param string|integer id - Fuse content ID.
     * @param string type - Fuse content type.
     * 
     * @return string - The URL
     */

    ContentUrl = ( id, type ) => {

        switch ( type.toLowerCase() ) {

            case "community":
            case "privatecommunity":

                return this.FuseUrl + `communities/${id}`;

            case "learning-plan":

                return this.FuseUrl + `learning/plans/${id}`;

            case "topic":

                return this.FuseUrl + `topics/${id}`

            case "user":

                return this.FuseUrl + `users/${id}`;

            default:

                return this.FuseUrl + `contents/${id}`;

        }

    }
    /**
     * Decode a Fuse API response.
     * 
     * @param string response - Unparsed response.
     * 
     * @return object|bool - Decoded JSON or 'false' on fail.
     */

    Decode = ( response ) => {

        return JSON.parse( response );

    }

    /**
     * Get inject variables.
     * 
     * @return object - Variable object.
     */

    InjectVars = () => {

        const { name } = Auth.User || {};
        const [ Name, GivenName, FamilyName ] = name || [];

        return {

            familyName: [ "Family name", FamilyName || "" ],
            givenName: [ "Given name", GivenName || "" ],
            name: [ "Name", Name || "" ]

        };

    }

    /*
     * Make a XHR request to the Fuse API.
     * 
     * @param string endpoint
     * @param object data - Optional form data.
     * @param function callback - Called when the request is completed.
     * @param string method - Request method. Defaults to 'GET'.
     * 
     * @return object - The XHR object.
     */

    Request = ( endpoint, data, callback, method = "GET" ) => {

        if ( !Auth.Token ) {

            console.error( "Fuse credentials are not ready." );
            return false;

        }

        // Since data is optional, the second parameter may instead
        // be the callback method.
        if ( !callback ) {

            callback = data;

        }

        if ( typeof callback !== "function" ) {

            callback = () => {};

        }

        const Data = data || {};
        const Query = [ `auth_token=${Auth.Token}` ];
        let FormData = null;

        if ( method === "POST" ) {

            FormData = API.FormData( Data );

        }

        else {

            for ( let key in Data ) {

                Query.push( key + "=" + encodeURI( Data[ key ] ) );

            }

        }

        const Url = this.Url( endpoint + "?" + Query.join( "&" ) );

        if ( !Url ) {

            callback( false );

            console.error( "No Fuse API URL has been configured." );
            return false;

        }

        const Xhr = new XMLHttpRequest();

        Xhr.open( method, Url, true );

        // On complete.
        Xhr.addEventListener( "load", (e) => {

            // Check for 200.
            if ( Xhr.readyState !== XMLHttpRequest.DONE ) {

                return;

            }

            // Check that the response is a proper JSON.
            const Response = this.Decode( Xhr.response );

            callback( Response );

        }, false );

        // On fail.
        Xhr.addEventListener( "error", (e) => {

            callback( false );

        }, false );

        Xhr.send( FormData );

        return Xhr;

    }

    SetContext = ( context, contextId ) => {

        this.Context = context;
        this.ContextId = contextId;

    }

    SetHost = ( host ) => {

        if ( this.OriginAllowed.indexOf( host ) < 0 || this.FuseOverride.indexOf( host ) >= 0 ) {

            host = this.FuseDefault;

        }

        const Version = Globals.Setting( "FuseApiVersion", 4.2 );

        this.ApiUrl = `${host}/api/v${Version}/`;
        this.FuseUrl = `${host}/`;

    }

    /**
     * Create a Fuse URL.
     * 
     * @param string uri - The request URI.
     * @param boolean api - Whether this should be an API URL. Defaults to true.
     * 
     * @return string - The URL
     */

    Url = ( uri, api = true ) => {

        if ( api ) {

            return this.ApiUrl ? this.ApiUrl + uri : '';

        }

        return this.FuseUrl ? this.FuseUrl + uri : '';

    }

}

export default new Fuse();