
/**!
 *  Widget gallery view
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./widgetgallery.scss";

import API from "Class/API";
import { ArrayClone, RandomToken } from "Functions";

import Button from "Components/UI/Button";
import IconItem from "Components/UI/IconItem";
import Preview from "Components/Layout/Preview";
import ScrollView from "Components/UI/ScrollView";
import Spinner from "Components/Feedback/Spinner";
import TextField from "Components/UI/Field/TextField";

class WidgetGallery extends React.Component {

    constructor( props ) {

        super( props );

        this.Content = false;
        this.Edit = false;
        this.Iteration = 0;
        this.Last = false;
        this.Limit = 10;
        this.Mounted = false;
        this.Scope = false;
        this.SearchDelay = 300;
        this.SearchTimer = false;

        this.state = {

            done: false,
            error: false,
            filter: "",
            loading: false,
            result: false,
            selected: ""

        };

    }

    /**
     * Make a blank search on mount.
     * 
     * @return void
     */

    componentDidMount() {

        this.Mounted = true;

        this.Search();

    }

    /**
     * Register unmount.
     * 
     * @return void
     */

    componentWillUnmount() {

        this.Mounted = false;

    }

    /**
     * Render a content item.
     * 
     * @param object item - Content object.
     * 
     * @return JSX - Content item.
     */

    Item = ( item ) => {

        const { selected } = this.state;
        const { blocked, content, count, edit, id, updated, scope } = item;

        if ( blocked || !content ) {

            return;

        }

        const { description, name, template } = content;
        const CA = [ "WidgetGalleryItem" ];

        if ( selected === id ) {

            CA.push( "Selected" );

        }

        const CS = CA.join( " " );

        return (

            <div
            
                className={ CS }
                key={ id }
                onClick={ e => this.OnSelect( e, content, scope, edit, id ) }
            
            >

                <Preview
                
                    className="ItemPreview WidgetGalleryItemPreview"
                    content={ `widget-${template}` }
                
                />

                <div className="ItemContent WidgetGalleryItemContent">

                    <div className="ItemName WidgetGalleryItemName">{ name || "Unnamed Widget" }</div>
                    <div className="ItemInfo WidgetGalleryItemDescription">{ description || "\u00a0" }</div>
                    <div className="ItemInfo WidgetGalleryItemInfo">

                        <div className="WidgetGalleryItemUpdated">{ `Updated at ${updated}` }</div>
                        <div className="WidgetGalleryItemCount">{ `Used ${count} time(s)` }</div>

                    </div>

                </div>

            </div>

        );

    }

    /**
     *  Fetch more results for the current filter.
     *  
     *  @return void.
     */
    
    LoadMore = () => {

        this.Search( true );
        
    }

    /**
     *  Callback when the close button is clicked.
     *  
     *  @return void.
     */

    OnClose = () => {

        const { id, onClose } = this.props;

        onClose( id );

    }

    /**
     *  Callback when the copy button is clicked.
     *  
     *  @return void.
     */

    OnCopy = (e) => {

        if ( !this.Content ) {

            return;

        }

        const { id, onCopy } = this.props;
        const Content = ArrayClone( this.Content );

        // Remove id to create new content from this object.
        delete Content.id;

        // Change the name accordingly.
        Content.name = `Copy of ${Content.name}`;

        onCopy( e, Content, id );

    }

    /**
     *  Callback when the load button is clicked.
     *  
     *  @return void.
     */

    OnLoad = (e) => {

        if ( !this.Content ) {

            return;

        }

        const { id, onLoad } = this.props;
        const Content = ArrayClone( this.Content );
        const Scope = ArrayClone( this.Scope );

        onLoad( e, Content, Scope, this.Edit, id );

    }

    /**
     *  Callback when a widget content item is clicked.
     * 
     *  @param object e - The event object.
     *  @param object content - The items content object.
     *  @param object scope - The items scope object.
     *  @param boolean edit - Whether the current user can edit this content.
     *  @param string|number id - The item id.
     *  
     *  @return void.
     */

    OnSelect = ( e, content, scope, edit, id ) => {

        this.Content = content;
        this.Edit = edit;
        this.Scope = scope;

        this.setState( {

            selected: id

        } );

    }

    /**
     * Make a content search request to the content API.
     * 
     * @param boolean loadMore - Whether to fetch more results instead of clearing.
     * 
     * @return void
     */

    Search = ( loadMore ) => {

        const { filter, result } = this.state;
        const Id = this.Last = RandomToken();

        this.setState( {

            error: false,
            limit: this.Limit,
            loading: true

        } );

        API.Request( "widget/search", {

            filter,
            offset: loadMore === true ? result.length : 0

        }, response => {

            if ( !this.Mounted || Id !== this.Last ) {

                return;

            }

            const { error, message, result: fetchedResult } = response;

            if ( error ) {

                this.setState( {

                    done: true,
                    error: message || "Error.",
                    loading: false,
                    result: false

                } );

            }

            else {

                this.Iteration++;

                this.setState( {

                    done: fetchedResult.length < this.Limit,
                    loading: false,
                    result: loadMore === true ? result.concat( fetchedResult ) : fetchedResult

                } );

            }

        } );

    }

    /**
     * Set the filter and reset the timeout when the filter changes.
     * 
     * @param object e - The event object.
     * @param string filter - The new filter.
     * 
     * @return void
     */

    SetFilter = ( e, filter ) => {

        clearTimeout( this.SearchTimer );

        this.Content = false;
        this.Edit = false;
        this.Scope = false;

        this.setState( {

            error: false,
            filter,
            loading: true,
            selected: ""

        } );

        this.SearchTimer = setTimeout( this.Search, this.SearchDelay );

    }

    render() {

        const { className } = this.props;
        const { done, filter, loading, result, selected } = this.state;
        const CA = [ "WidgetGallery" ];
        const Content = [];

        if ( className ) {

            CA.push( className );
            
        }

        const CS = CA.join( " " );

        if ( result && result.length ) {

            const Result = [];

            result.forEach( ( item, index ) => {

                Result.push( this.Item( item, index ) );

            } );

            Result.push( <IconItem
            
                className="WidgetGalleryButton"
                disabled={ done }
                feather="PlusCircle"
                key="fetch"
                label="Load more"
                loading={ loading }
                onClick={ this.LoadMore }
            
            /> );

            Content.push(

                <ScrollView
                
                    className="WidgetGalleryResults"
                    key="results"
                
                >{ Result }</ScrollView>

            );

        }

        else if ( !loading && result && filter ) {

            Content.push(

                <div className="WidgetGalleryEmpty" key="no-match">

                    No content matches <b>{ filter }</b>.

                </div>

            );

        }

        else if ( !loading && result ) {

            Content.push(

                <div className="WidgetGalleryEmpty" key="no-content">

                    No content has been added.

                </div>

            );

        }

        else if ( loading && ( !result || !result.length ) ) {

            Content.push(

                <Spinner
                
                    className="WidgetGallerySpinner"
                    key="spinner"
                    overlay={ true }
                    
                />

            );

        }

        return (

            <div className={ CS }>

                <div className="WidgetGalleryWrapper">

                    <div className="WidgetGallerySearch">

                        <TextField
                    
                            feather="Search"
                            loading={ loading }
                            onChange={ this.SetFilter }
                            onInput={ this.SetFilter }
                            placeholder="Search for content..."
                            value={ filter }
                        
                        />

                    </div>

                    <div className="WidgetGalleryContent">

                        { Content }

                    </div>

                </div>

                <div className="WidgetGalleryTray">

                    <Button
                    
                        disabled={ !selected }
                        label="Load"
                        onClick={ this.OnLoad }
                        title="Load this content into the selected widget."
                    
                    />

                    <Button
                    
                        disabled={ !selected }
                        hollow={ true }
                        label="Copy"
                        onClick={ this.OnCopy }
                        title="Copy this content into the selected widget."
                    
                    />

                    <Button
                    
                        hollow={ true }
                        label="Close"
                        onClick={ this.OnClose }
                    
                    />

                </div>

            </div>

        );

    }

}

WidgetGallery.propTypes = {

    className: PropTypes.string,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    onClose: PropTypes.func,
    onCopy: PropTypes.func,
    onLoad: PropTypes.func

};

WidgetGallery.defaultProps = {

    className: "",
    id: "",
    onClose: () => {},
    onCopy: () => {},
    onLoad: () => {}

};

export default WidgetGallery;