/**!
 *  Collapsable Group
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./group.scss";
import Globals from "Class/Globals";
import Collapsable from "Components/Layout/Collapsable";
import Icon from "Components/Layout/Icon";

class Group extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            collapsed: null
        };
    }

    componentDidMount()
    {
        const {collapsed} = this.props;
        this.SetCollapse(collapsed);
    }

    componentDidUpdate(prevProps)
    {
        const {collapsed: c1} = this.props;
        const {collapsed: c2} = prevProps;
        if (c1 !== c2)
        {
            this.SetCollapse(c1);
        }
    }

    SetCollapse = (collapsed) =>
    {
        const {collapsedDefault, name} = this.props;
        if (name && collapsed === null)
        {
            collapsed = Globals.Storage(`group-${name}`);
        }
        else if (name)
        {
            Globals.Storage(`group-${name}`, collapsed);
        }
        if (collapsed === undefined)
        {
            collapsed = collapsedDefault;
        }
        this.setState({collapsed});
    }

    Toggle = () =>
    {
        const {collapsed} = this.state;
        this.SetCollapse(!collapsed);
    }

    render()
    {
        const {
            children,
            className,
            title
        } = this.props;
        let {collapsed} = this.state;
        const CA = ["Group"];
        if (collapsed === null)
        {
            return "";
        }
        if (collapsed)
        {
            CA.push("Collapsed");
        }
        else
        {
            CA.push("Expanded");
        }
        if (className)
        {
            CA.push(className);
        }
        return (
            <div className={CA.join(" ")}>
                <div className="GroupTitle" onClick={this.Toggle}>
                    <span className="GroupTitleHeading">{title}</span>
                    <Icon className="GroupTitleIcon" feather="ChevronDown"/>
                </div>
                <Collapsable className="GroupContent" collapsed={collapsed}>{children}</Collapsable>
            </div>
        );
    }
}

Group.propTypes =
{
    className: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string
};

Group.defaultProps =
{
    className: "",
    collapsed: null,
    collapsedDefault: false,
    name: "",
    title: ""
};

export default Group;