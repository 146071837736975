/**!
 *  Link widget.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./link.scss";
import API from "Class/API";
import Fuse from "Class/Fuse";
import Parser from "Class/Parser";
import {ObjectCompare} from "Functions";
import Link from "Components/UI/Link";
import Spinner from "Components/Feedback/Spinner";

class WidgetLink extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields = this.SetFields({
            title:
            {
                insert: true,
                label: "Label",
                reset: true,
                type: "textarea"   
            },
            externalLink:
            {
                label: "External link",
                type: "checkbox"
            },
            content:
            {
                displayIf: ["externalLink", "!=", true],
                label: "Content",
                type: "content"
            },
            contentNotice:
            {
                displayIf: ["externalLink", "!=", true],
                label: "Label and Image will replace the contents preview and name if specified.",
                type: "notice"
            },
            url:
            {
                displayIf: ["externalLink", "==", true],
                label: "URL",
                type: "url",
                default: "https://"
            },
            urlBlank:
            {
                displayIf: ["externalLink", "==", true],
                label: "Open in a new tab",
                type: "checkbox",
                default: true
            }
        });
        this.state =
        {
            content: false,
            contentObject: false,
            error: false,
            loading: false
        };
        this.Name = "Link";
    }

    /**
     * Load content on mount.
     * @return void
     */

    componentDidMount()
    {
        const {content, contentObject} = this.props;
        const {content: fuseContent, externalLink} = content;
        if (contentObject)
        {
            this.setState({content: contentObject.id, contentObject});
        }
        else if (!externalLink)
        {
            this.Load(fuseContent);
        }
    }

    /**
     * Update content when a new ID is received.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content: c1} = this.props;
        const {content: c2} = prevProps;
        const {content: c3, externalLink: e1} = c1;
        const {content: c4, externalLink: e2} = c2;
        if (!e1 && (!ObjectCompare(c3, c4) || e1 !== e2))
        {
            this.Load(c3);
        }
    }

    /**
     * Load content from the Fuse API.
     * @param array|integer item - [contentId, type] or just contentId.
     * @return void
     */

    Load = (item) =>
    {
        if (!item || (Array.isArray(item) && !item.length))
        {
            this.setState({
                content: 0,
                contentObject: false,
                error: false,
                loading: false
            });
        }
        else if (typeof item === "object" && typeof item[0] === "object")
        {
            this.Load(item[0]);
        }
        else if (typeof item === "object")
        {
            this.setState({
                content: item[0],
                error: false,
                loading: true
            });
            this.LoadContent(item[0], item[1]);
        }
        else
        {
            this.setState({
                content: item,
                error: false,
                loading: true
            });
            /**
             * Check content type in the backend DB before fetching.
             */
            API.Request("content/cache-read", {id: item}, response =>
            {
                const {cache, error} = response;
                if (error)
                {
                    this.setState({error: true, loading: false});
                }
                else
                {
                    this.LoadContent(item, cache.type);
                }
            });
        }
    }

    /**
     * Load content data from fuse.
     * @param integer id - The content id.
     * @param string type - The content type.
     * @return void
     */

    LoadContent = (id, type) =>
    {
        Fuse.Content(id, type, content =>
        {
            if (!content)
            {
                this.setState({error: true, loading: false});
            }
            else
            {
                this.setState({loading: false, contentObject: content});
            }
        });
    }

    render()
    {
        const {content} = this.props;
        const {contentObject, loading} = this.state;
        const {backgroundImage, externalLink, grayscale, title, url, urlBlank} = this.Content();
        const CA = ["WidgetLink"];
        const Content = [];
        if (grayscale)
        {
            CA.push("Grayscale");
        }
        let Background, Label, Url;
        let Target = "_top";
        if (loading)
        {
            Background = backgroundImage ? parseInt(backgroundImage[0], 10) : 0;
            Label = title;
            Content.push(<Spinner
                className="WidgetLinkSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (externalLink)
        {
            Background = backgroundImage ? parseInt(backgroundImage[0], 10) : 0;
            Label = title;
            Target = urlBlank ? "_blank" : "_top";
            Url = url;
        }
        else if (!externalLink && contentObject)
        {
            const {id, name, preview, type} = contentObject;
            Background = backgroundImage ? parseInt(backgroundImage[0], 10) : preview;
            Label = title || name;
            Url = Fuse.ContentUrl(id, type);
        }
        else
        {
            CA.push("Disabled");
            Label = title;
        }
        const CS = this.ClassNames(CA, true, Background);
        return (
            <div className={CS} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage(Background)}
                <Link
                    className="WidgetLinkContent"
                    href={Url}
                    target={Target}
                ><div className="WidgetLinkContentLabel Heading">{Parser.ParseParagraphs(Label)}</div></Link>
            </div>
        );
    }
}

export default WidgetLink;