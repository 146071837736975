
/**!
 *  Login interface
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./login.scss";

import Button from "Components/UI/Button";
import Overlay from "Components/Layout/Overlay";
import TextField from "Components/UI/Field/TextField";
import Auth from "../../../Class/Auth";

class ViewLogin extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            username: "",
            password: "",
            error: "",
            loading: false

        };

    }

    OnInput = ( e, value, field ) => {

        const State = {};

        State[ field ] = value;

        this.setState( State );

    }

    OnLogin = () => {

        const { username, password } = this.state;

        this.setState( {

            error: "",
            loading: true

        } );

        Auth.Login( username, password, response => {

            console.log( "Login", response );

        } );

    }

    render() {

        const { username, password, loading } = this.state;

        return (

            <div className="Login">

                <Overlay
                
                    className="LoginDialog"
                    title="Login"
                    
                >

                    <TextField
                    
                        disabled={ loading }
                        id="username"
                        label="E-mail or Username"
                        onChange={ this.OnInput }
                        type="email"
                        value={ username }
                    
                    />

                    <TextField
                    
                        disabled={ loading }
                        id="password"
                        label="Password"
                        onChange={ this.OnInput }
                        type="password"
                        value={ password }
                    
                    />

                    <Button
                    
                        disabled={ !username || !password }
                        label="Login"
                        loading={ loading }
                        onClick={ this.OnLogin }
                    
                    />

                </Overlay>

            </div>

        );

    }

}

export default ViewLogin;