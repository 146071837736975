
/*!
 *  Counter.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./counter.scss";

class Counter extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            count: 0,
            decimals: 0,
            previous: 0,
            width: 0,
            height: 0

        };

    }

    componentDidMount() {

        const { count } = this.props;
        const Decimals = ( String( count ).split( "." )[1] || "" ).length;

        this.setState( {

            count,
            decimals: Decimals
            
        } );

        setTimeout( this.SetSize, 0 );

    }

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { count } = nextProps;

        if ( count !== this.state.count ) {

            const Decimals = ( String( count ).split( "." )[1] || "" ).length;

            this.setState( {

                count,
                decimals: Decimals,
                previous: this.state.count
                
            } );

            setTimeout( this.SetSize, 0 );

        }

    }

    SetSize = () => {

        const { number } = this.refs;

        this.setState( {

            width: number.offsetWidth,
            height: number.offsetHeight

        } );

    }

    render() {

        const { className, unit } = this.props;
        const { count, decimals, previous, width, height } = this.state;
        const CA = [ "Counter", `Decimals${decimals}` ];

        if ( className ) {
            
            CA.push( className );

        }

        const CS = CA.join( " " );
        const Direction = previous > count ? "Down" : "Up";

        return (

            <div className={ CS }>

                <div className="CounterNumberContainer" style={{ width, height }}>

                    { count !== previous ? <div
                    
                        className={ `CounterNumber CounterNumberPrevious ${Direction}` }
                        key={ "previous" + previous }
                        
                    >

                        { previous }
                            
                    </div> : "" }

                    <div
                    
                        className={ `CounterNumber CounterNumberCurrent ${Direction}` }
                        key={ "current" + count }
                        ref="number"
                        
                    >

                        { count }
                            
                    </div>

                </div>

                <div className="CounterUnit">{ unit }</div>
            
            </div>

        );

    }

}

Counter.propTypes = {

    className: PropTypes.string,
    count: PropTypes.number,
    unit: PropTypes.string

};

Counter.defaultProps = {

    className: "",
    count: 0,
    unit: ""

}

export default Counter;