/**!
 *  Poll.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./poll.scss";
import API from "Class/API";
import Globals from "Class/Globals";
import {DateStamp, RandomToken} from "Functions";
import Icon from "Components/Layout/Icon";
import Spinner from "Components/Feedback/Spinner";

class WidgetPoll extends Widget
{
    constructor(props)
    {
        super(props);
        // Create default end of poll at 12:00 two weeks from now.
        const T = new Date(); T.setDate(T.getDate() + 14);
        const E = DateStamp(T) + " 12:00";
        this.Fields = this.SetFields({
            question:
            {
                insert: true,
                label: "Question",
                type: "textarea" 
            },
            answers:
            {
                addLabel: "Add answer",
                label: "Answers",
                type: "repeater",
                fields:
                {
                    answer:
                    {
                        insert: true,
                        label: "Answer"
                    }
                }
            },
            ends:
            {
                label: "Ends At",
                type: "datetime",
                default: E
            },
            export:
            {
                callback: "exportPoll",
                label: "Export Results",
                type: "button",
            },
            clear:
            {
                callback: "clearPoll",
                hollow: true,
                label: "Clear Results",
                type: "button",
            }
        });
        this.Ends = false;
        this.LoadDelay = 300;
        this.LoadTimer = false;
        this.LoadToken = false;
        this.Mounted = false;
        this.Name = "Poll";
        this.PollId = false;
        this.RefreshInterval = 10000;
        this.RefreshTimer = false;
        this.state =
        {
            content: 0,
            error: false,
            expired: false,
            loading: false,
            results: false,
            sending: false,
            total: 0,
            voted: false
        };
    }

    /**
     * Load poll results on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        const {content, contentId} = this.props;
        const {ends, id} = content || {};
        Globals.Listen(`request-${contentId}`, this.OnRequest);
        this.SetEnd(ends);
        this.Load(id);
    }

    /**
     * Reload poll if the content id changes.
     * @return void
     */

    componentDidUpdate(prevProps)
    {
        const {content, contentId: i1} = this.props;
        const {contentId: i2, ends: e2} = prevProps;
        const {ends: e1, id} = content || {};
        if (e1 !== e2)
        {
            this.SetEnd(e1);
        }
        if (id !== this.PollId)
        {
            this.Load(id);
        }
        if (i1 !== i2)
        {
            Globals.Remove(`request-${i2}`, this.OnRequest);
            Globals.Listen(`request-${i1}`, this.OnRequest);
        }
    }

    /**
     * Register unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        const {contentId} = this.props;
        Globals.Remove(`request-${contentId}`, this.OnRequest);
        clearTimeout(this.LoadTimer);
        clearTimeout(this.RefreshTimer);
    }

    /**
     * Load poll results.
     * @param integer id - Content (poll) id.
     * @return void
     */

    Load = (id) =>
    {
        this.PollId = id;
        clearTimeout(this.LoadTimer);
        this.setState({
            content: id,
            error: false,
            loading: true
        });
        const Token = this.LoadToken = RandomToken();
        this.LoadTimer = setTimeout(() =>
        { 
            API.Request("widget/poll-results", {id}, response =>
            {
                if (!this.Mounted || Token !== this.LoadToken)
                {
                    return false;
                }
                const {error, message, poll} = response;
                if (error)
                {
                    this.setState({
                        error: message || "Error loading poll.",
                        loading: false
                    });
                }
                else
                {
                    const {results, voted} = poll;
                    let Total = 0;
                    if (results)
                    {
                        for (let id in results) Total += results[id];
                    }
                    this.setState({
                        loading: false,
                        results,
                        total: Total,
                        voted
                    });
                }
            });
        }, this.LoadDelay);
    }

    OnRequest = (request) =>
    {
        const {content} = this.state;
        const {type} = request;
        switch (type)
        {
            case "clearPoll":
                this.setState({error: false, loading: true});
                const Token = this.LoadToken = RandomToken();
                API.Request("widget/poll-clear", {id: content}, response =>
                {
                    if (!this.Mounted || Token !== this.LoadToken)
                    {
                        return;
                    }
                    const {error, message, poll} = response;
                    if (error)
                    {
                        this.setState({
                            error: message || "Error clearing poll.",
                            loading: false
                        });
                    }
                    else
                    {
                        const {results, voted} = poll;
                        let Total = 0;
                        if (results)
                        {
                            for (let id in results) Total += results[id];
                        }
                        this.setState({
                            loading: false,
                            results,
                            total: Total,
                            voted
                        });
                    }
                });
                break;
            default:
        }
    }

    /**
     * Check if the poll has expired and reset the timer.
     * @return void
     */

    Refresh = () =>
    {
        const {expired} = this.state;
        const Now = new Date();
        const Expired = this.Ends <= Now;
        const Delta = Math.abs(this.Ends - Now);
        if (Expired !== expired)
        {
            this.setState({expired: Expired});
        }
        this.RefreshTimer = setTimeout(this.Refresh, Math.min(Delta, this.RefreshInterval));
    }

    /**
     * Render answer result bar.
     * @param integer id - Answer id.
     * @return JSX - The result bar.
     */

    Result = (id) =>
    {
        const {results, total} = this.state;
        const Count = results[id] || 0;
        const Percent = total ? Count / total : 0;
        const Style = {width: Percent * 100 + "%"};
        const Label = Math.round(Percent * 100) + "%";
        return (
            <div className="WidgetPollAnswerBar">
                <div className="WidgetPollAnswerBarFill" style={Style}>
                    <span>{Label}</span>
                </div>
            </div>
        );
    }

    /**
     * Set the end date and time of the poll.
     * @param string formatted - Formatted datetime.
     * @return void
     */

    SetEnd = (formatted) =>
    {
        clearTimeout(this.RefreshTimer);
        this.Ends = Date.parse(formatted);
        this.Refresh()
    }

    /**
     * Vote for an option,
     * @param string answer - Answer id.
     * @return void
     */

    Vote = (answer) =>
    {
        const {content: id, expired, sending} = this.state;
        if (expired || sending)
        {
            return;
        }
        this.setState({
            error: false,
            sending: answer
        });
        API.Request("widget/poll-vote", {answer, id}, response =>
        {
            const {error, poll} = response;
            if (error)
            {
                this.setState({sending: false});
            }
            else
            {
                const {results, voted} = poll;
                let Total = 0;
                if (results)
                {
                    for (let id in results) Total += results[id];
                }
                this.setState({
                    results,
                    sending: false,
                    total: Total,
                    voted
                });
            }
        });
    }

    render()
    {
        const {error, expired, loading, sending, total, voted} = this.state;
        const {answers, backgroundColor, question} = this.Content();
        const CA = this.ClassNames(["WidgetPoll"]);
        const CheckColor = Array.isArray(backgroundColor) ? backgroundColor[0] : backgroundColor || "#000000";
        if (expired)
        {
            CA.push("Expired");
        }
        if (sending)
        {
            CA.push("Sending");
        }
        if (voted)
        {
            CA.push("Voted");
        }
        const Content = [];
        if (loading)
        {
            Content.push(<Spinner
                className="WidgetPollSpinner"
                key="spinner"
                overlay={true}
            />);
        }
        else if (!error)
        {
            const Answers = [];
            (answers || []).forEach((answer, index) => {
                const {answer: option, id} = answer;
                const ACA = ["WidgetPollAnswer"];
                const Checked = id === voted;
                if (Checked)
                {
                    ACA.push("Checked");
                }
                Answers.push(
                    <div
                        className={ACA.join(" ")}
                        key={id || index}
                        onClick={() => this.Vote(id)}   
                    >
                        {sending === id ? <Spinner
                            className="WidgetPollAnswerSpinner"
                            size={22}
                        /> : <div className="WidgetPollAnswerButton">
                            {Checked ? <Icon feather="Check" fill={CheckColor}/> : ""}
                        </div>}
                        <span>{option}</span>
                        {this.Result(id)}   
                    </div>
               );
            });
            Content.push(<div className="WidgetPollContent" key="content">
                <div className="WidgetPollContentText">
                    <h2>{question}</h2>
                </div>
                <div className="WidgetPollAnswers">
                    {Answers}
                </div>
            </div>);
        }
        return (
            <div className={CA.join(" ")} ref={widget => this.RefWidget = widget} style={this.Style()}>
                {this.Toolbar()}
                {this.BackgroundImage()}
                {Content}
                {total ? <div className="WidgetPollTotal">{total} {total.length === 1 ? "vote" : "votes"}</div> : ""}
            </div>
        );
    }
}

export default WidgetPoll;