/**!
 *  Widget that displays different content depending on a set of
 *  conditions.
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import Widget from "../../widget.js";
import "./conditional.scss";
import Globals from "Class/Globals";
import {ArrayClone, CanForEach} from "Functions";
import ViewWidget from "Components/View/Widget";

class WidgetConditional extends Widget
{
    constructor(props)
    {
        super(props);
        this.Fields =
        {
            widgets:
            {
                label: "Widgets",
                type: "conditionalWidget"
            },
            widgetsNotice:
            {
                label: "The widgets will be evaluated from top to bottom. The first one which conditions are met will be the one that is displayed to the user. It's always a good idea to have a 'default' widget with no conditions at the bottom.",
                type: "notice"
            }
        };
        this.RefreshTimer = false;
        this.Mounted = false;
        this.Name = "Conditional";
        this.state =
        {
            activeId: Globals._ViewActive
        };
    }

    /**
     * Add listeners and refresh timer on mount.
     * @return void
     */

    componentDidMount()
    {
        this.Mounted = true;
        // Only listen for active widget when editable.
        const {editContainer, editContent} = this.props;
        if (editContainer || editContent)
        {
            Globals.Listen("active-view", this.OnActive);
        }
        this.OnRefresh();
    }

    /**
     * Remove listener and refresh timer on unmount.
     * @return void
     */

    componentWillUnmount()
    {
        this.Mounted = false;
        Globals.Remove("active-view", this.OnActive);
        clearTimeout(this.RefreshTimer);
    }

    /**
     * Callback when global focus changes.
     * @return void
     */

    OnActive = (activeId) =>
    {
        this.setState({activeId});
    }

    /**
     * Re-render the widget and (re)start the timer.
     * @return void
     */

    OnRefresh = () =>
    {
        if (!this.Mounted)
        {
            return;
        }
        // Re-render at every full minute + one second.
        this.RefreshTimer = setTimeout(this.OnRefresh, (61 - (new Date()).getSeconds()) * 1000);
        this.forceUpdate();
    }

    render()
    {
        const {
            active,
            ancestors,
            appearance,
            content,
            contentId,
            context,
            contextId,
            hover,
            editContainer,
            editContent,
            toolbarOffset
        } = this.props;
        const {activeId} = this.state;
        const {widgets} = content || {};
        const CA = ["Widget", "WidgetConditional"];
        const Ancestors = ArrayClone(ancestors);
        const Manager = editContainer || editContent;
        let Content;
        Ancestors.push(contentId);
        if (active)
        {
            CA.push("Active");
        }
        if (hover)
        {
            CA.push("Hover");
        }
        if (CanForEach(widgets) && Manager)
        {
            CA.push("ManagerMode");
            Content = [];
            let Active = false;
            let Match = false;
            widgets.forEach(({id: widgetId}) =>
            {
                if (widgetId === activeId)
                {
                    Active = widgetId;
                }
            });
            widgets.forEach(({conditions, id: widgetId}) =>
            {
                if (Active === widgetId || (!Active && !Match && (typeof conditions !== "object" || Globals.CheckConditions(conditions))))
                {
                    Match = widgetId;
                }
                Content.push(
                    <div
                        className={Match === widgetId ? "WidgetConditionalItem Active" : "WidgetConditionalItem"}
                        key={widgetId}
                    >
                        <ViewWidget
                            ancestors={Ancestors}
                            appearance={appearance}
                            autoAdjust={false}
                            context={context}
                            contextId={contextId}
                            name={widgetId}
                            onAutoAdjust={this.OnItemAutoAdjust}
                            onFill={this.OnItemFill}
                            onHeight={this.OnItemHeight}
                            parent={ contentId }
                            ref={widgetId}
                            toolbarOffset={toolbarOffset + 1}
                            universal={true}
                            x={0}
                            y={0}
                        />
                    </div>
                );
            });
        }
        else if (CanForEach(widgets))
        {
            widgets.forEach(({conditions, id: widgetId}) =>
            {
                if (!Content && (typeof conditions !== "object" || Globals.CheckConditions(conditions)))
                {
                    Content =(
                        <div className="WidgetConditionalItem Active">
                            <ViewWidget
                                ancestors={Ancestors}
                                appearance={appearance}
                                autoAdjust={false}
                                context={context}
                                contextId={contextId}
                                name={widgetId}
                                onAutoAdjust={this.OnItemAutoAdjust}
                                onFill={this.OnItemFill}
                                onHeight={this.OnItemHeight}
                                parent={contentId}
                                ref={widgetId}
                                toolbarOffset={toolbarOffset + 1}
                                universal={true}
                                x={0}
                                y={0}
                            />
                        </div>
                    );
                }
            });
        }
        return (
            <div className={CA.join(" ")} ref="widget">
                {this.Toolbar(true)}
                {Content}
            </div>
        );
    }
}

export default WidgetConditional;